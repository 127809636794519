import React, { useState, useEffect } from "react";

import { IonGrid, IonRow, IonCol, IonButton, IonImg } from "@ionic/react";
import { Input, InputSelect } from "../../components/Inputs/Input";
import { BUSCARCATALOGO, REFRESH } from "../../const/imgs";
import { completeCodigo, getStatus } from "../../const/functions";

import dayjs from "dayjs";
import {
  getDetailAll,
  getWebOrderDownload,
  getSupport,
} from "../../api/pedido/pedido";

import exportToExcel from "./exportToExcel";

interface PropOrderList {
  loading: Function;
  setAllOrder: Function;
  //setDataSearch: Function
  setOrder: Function;
  setFillter: Function;
  setRender: Function;
  allOrder: any[];
  //orderFillter: any[]
  status: any[];
  dataNotification: any[];
  fetchGetNotifications: Function;
  fetchData: Function;
  rol: string;
}

export function OrderList({
  setOrder,
  allOrder,
  setAllOrder,
  setFillter,
  status,
  setRender,
  loading,
  dataNotification,
  fetchGetNotifications,
  fetchData,
  rol,
}: PropOrderList) {
  const [idStatus, setIdStatus] = useState("");
  const [ini, setIni] = useState<any>(dayjs().add(-1, "d"));
  const [fin, setFin] = useState<any>(dayjs());

  const [search, setSearch] = useState<any>("");
  const [dataSearch, setDataSearch] = useState<any>([]);

  const preSetSearch = (v: string) => {
    if (v === "") {
      setSearch(v);
      return;
    }

    let newData: any[] = [];

    allOrder.forEach((obj: any) => {
      const filter = `${completeCodigo(
        "" + obj?.codigo
      )} ${obj?.user?.identification?.toUpperCase()} ${obj?.user?.lastName?.toUpperCase()} ${obj?.user?.name?.toUpperCase()} ${
        obj?.store?.phone
      } ${obj?.store?.name?.toUpperCase()}`;
      if (!obj?.user?.identification?.toUpperCase) {
        console.info("filter", filter);
      }

      const words = v.toUpperCase().split(" ");
      let flag = true;
      words.forEach((word: any, i: number) => {
        if (!flag) {
          return;
        }

        if (!filter.includes(word)) {
          flag = false;
        }

        if (flag && i === words.length - 1) {
          if (!newData) newData = [obj];
          else newData.push(obj);
        }
      });
    });
    console.info("newData", newData);
    setDataSearch(newData);

    setSearch(v);
  };

  const getDetail = async (order: any, i: number) => {
    try {
      loading(true);

      const res = await getDetailAll(order.id);
      const orderSupport = await getSupport(order.id);

      order.support = orderSupport.data.support;

      order.detail = res.data.detail;
      order.modification = res.data.modification;
      allOrder[i] = order;
      setAllOrder(allOrder);

      // order.date = dayjs(order.date, 'YYYY-MM-DD')
      order.date = dayjs(order.date).add(5, "h");

      setOrder(order);

      loading(false);
    } catch (error) {
      console.log("errrorr", error);
    }
  };

  const selectOrder = (order: any, i: number) => {
    if (!order.detail) {
      getDetail({ ...order }, i);
    } else {
      setOrder({ ...order });
      setRender();
    }
  };

  useEffect(() => {
    let tempData: any[] = [];
    let newData: any[] = [];

    if (idStatus === "15965100") {
      tempData = [...allOrder];
    } else {
      allOrder.forEach((order: any) => {
        if (order.idStatus === idStatus) tempData.push(order);
      });
      //console.log('newdata',tempData)
    }
    //console.log("tempDatatempDatatempData", tempData)
    tempData.forEach((order: any) => {
      const deliveryDate = dayjs(order.promisedDeliveryDate);
      if (deliveryDate.isBefore(fin) && deliveryDate.isAfter(ini)) {
        if(order.user && order.store){
          newData.push(order);
        }
      }
    });
    setDataSearch(newData);
    setFillter(true);
  }, [idStatus, ini, fin, allOrder]);

  useEffect(() => {
    if (status.length) {
      setIdStatus(status[0].id);
    }
  }, [status]);

  const fetchDataDownload = async (ids: any[]) => {
    try {
      loading(true);
      const res = await getWebOrderDownload(ids);
      let info: any[] = [];
      res.data.forEach((order: any, i: number) => {
        //if(i === 0) {
        info.push({
          Estatus: getStatus(order.idStatus),
          "# Orden": completeCodigo("" + order.codigo),
          "Fecha de entrega": order.deliveryWindow.split(" ; ")[0],
          Franja: order.deliveryWindow.split(" ; ")[1],
          "Tipo de pago": getStatus(order.idPaymentMethod),
          "Doc comprador": order.user.identification,
          Nombre: order.user.lastName + " " + order.user.name,
          "Nombre tienda": order.store?.name,
          Direccion: order.store.addres ? order.store.addres : "",
          Localidad: order.store.locality ? order.store.locality : "",
          Barrio: order.store.neighborhood ? order.store.neighborhood : "",
          Total: order.totalAmount - order.discountAmount,
        });
        //}
        order.orderItems.forEach((orderDet: any, j: number) => {
          if (j === 0) {
            info.push({
              Estatus: "", //getStatus(order.idStatus),
              "# Orden": "", //order.codigo,
              "Fecha de entrega": "", //order.deliveryWindow.split(' ; ')[0],
              Franja: "", //order.deliveryWindow.split(' ; ')[1],
              "Tipo de pago": "EAN",
              "Doc comprador": "Nombre Producto",
              Nombre: "Nombre Promocion",
              "Nombre tienda": "Presentacion",
              Direccion: "Contenido",
              Localidad: "Cantidad",
              Barrio: "Precio",
              Total: "Descuento",
              Subtotal: "SubTotal",
            });
          }
          info.push({
            Estatus: "",
            "# Orden": "",
            "Fecha de entrega": "",
            Franja: "",
            "Tipo de pago": orderDet.product ? orderDet.product.sku : "",
            "Doc comprador": orderDet.product ? orderDet.product.name : "",
            Nombre: orderDet.promotion ? orderDet.promotion.name : "",
            "Nombre tienda": orderDet.product
              ? orderDet.presentation.detail
              : "",
            Direccion: orderDet.product ? orderDet.product.content : "",
            Localidad: orderDet.quantity,
            Barrio: orderDet.price /*- orderDet.discount*/,
            Total: orderDet.discount,
            Subtotal: (orderDet.price - orderDet.discount) * orderDet.quantity,
          });
        });
      });
      exportToExcel(info);
      /*
    res.data.forEach((order: any, i: number) => {
      if(i === 0) {
        info.push(
          ['Estatus','#Orden', 'Fecha de entrega', 'Franja', 'Tipo de pago', 'Doc Comprador', 'Nombre', 'Nombre tienda' , 'Direccion' , 'Localidad' , 'Barrio', 'Total']
        )
      }
      info.push([
        getStatus(order.idStatus), order.codigo, order.deliveryWindow.split(' ; ')[0], order.deliveryWindow.split(' ; ')[1], getStatus(order.idPaymentMethod), order.user.identification, order.user.lastName + ' ' + order.user.name, order.store.name, order.store.addres ? order.store.addres : '', order.store.locality ? order.store.locality : '', order.store.neighborhood ? order.store.neighborhood : '', order.totalAmount
      ])

      order.orderItems.forEach((orderDet:any, j: number) => {
        if(j === 0) {
          info.push(
            ['', '', '', '', 'EAN', 'Nombre Producto', 'Nombre Promocion', 'Presentacion' , 'Contenido' , 'Cantidad' , 'Precio' , 'Descuento' , 'Sub-Total'],
          )
        }
        info.push(
          ['', '', '', '', orderDet.product ? orderDet.product.sku : '', orderDet.product ? orderDet.product.name : '', orderDet.promotion ? orderDet.promotion.name : '', orderDet.product ? orderDet.presentation.detail : '' , orderDet.product ? orderDet.product.content : '', orderDet.quantity , (orderDet.price - orderDet.discount), orderDet.discount , ((orderDet.price - orderDet.discount ) * orderDet.quantity)],
        )
      });
    });
    */
      console.log("Res", res);

      /*
      exportToCsv('export.csv', [
        ...info
      ])
    */

      loading(false);
    } catch (error) {
      console.log("errrorr", error);
    }
  };
  /*
  const exportToCsv = (filename: any, rows: any) => {

    var processRow = (row: any) => {
        var finalVal = '';
        for (var j = 0; j < row.length; j++) {
            var innerValue = row[j] === null ? '' : row[j].toString();
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString();
            };
            var result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0)
                result = '"' + result + '"';
            if (j > 0)
                finalVal += ';';
            finalVal += result;
        }
        return finalVal + '\n';
    };

    var csvFile = '';
    for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
    }
    
    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
*/
  const download = () => {
    //console.log("dataSearch", dataSearch)
    //let info: any[] = []
    let ids: any[] = [];

    dataSearch.forEach((element: any) => {
      ids.push(element.id);
    });

    fetchDataDownload(ids);
  };

  return (
    <IonGrid>
      <IonRow></IonRow>
      <IonRow>
        <IonCol size={"4"}>
          <InputSelect
            txt="Estado"
            onInput={(w: string) => setIdStatus(w)}
            data={status}
            positionTxt={"floating"}
            value={idStatus}
            propId={"id"}
            propTxt={"name"}
          />
        </IonCol>

        <IonCol size={"4"}>
          <Input
            txt="Desde"
            onInput={(value: string) =>
              setIni(dayjs(value).hour(0).minute(0).second(9))
            }
            value={ini.format("YYYY-MM-DD")}
            positionTxt={"floating"}
            type={"date"}
          />
        </IonCol>

        <IonCol size={"4"}>
          <Input
            txt="Hasta"
            onInput={(value: string) =>
              setFin(dayjs(value).hour(23).minute(59).second(59))
            }
            value={fin.format("YYYY-MM-DD")}
            positionTxt={"floating"}
            type={"date"}
          />
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol size={"11"}>
          <Input
            txt="Buscar"
            onInput={(w: string) => {
              preSetSearch(w);
            }}
            value={search}
            positionTxt={"floating"}
            type={"text"}
            icon2={BUSCARCATALOGO}
            onClickIcon2={() => {}}
          />
        </IonCol>

        <IonCol
          size={"1"}
          onClick={() => {
            preSetSearch("");
            fetchData();
          }}
          style={{ margin: "auto" }}
        >
          <IonImg className={"btnRefresh"} src={REFRESH} />
        </IonCol>
      </IonRow>

      <IonRow key={-1} className="UserCardBackUser2">
        <IonCol size={"2"} className="userCardCol ion-text-justify ">
          Código
        </IonCol>
        <IonCol size={"2"} className="userCardCol ion-text-justify ">
          Identificación
        </IonCol>
        <IonCol size={"3"} className="userCardCol ion-text-justify ">
          Nombre
        </IonCol>
        <IonCol size={"2"} className="userCardCol ion-text-justify ">
          Teléfono
        </IonCol>
        <IonCol size={"3"} className="userCardCol ion-text-justify ">
          Tienda
        </IonCol>
      </IonRow>

      <div className="OrderList" style={{ overflowY: "scroll" }}>
        {!setDataSearch.length &&
          allOrder.map((order: any, i: number) => {
            return (
              <IonRow key={i} onClick={() => selectOrder(order, i)}>
                <IonCol
                  size={"2"}
                  className="txtGreen userCardCol ion-text-justify "
                >
                  {`${completeCodigo("" + order.codigo)} `}
                </IonCol>
                <IonCol
                  size={"2"}
                  className="txtGreen userCardCol ion-text-justify "
                >
                  {`${order.user.identification}`}
                </IonCol>
                <IonCol
                  size={"3"}
                  className="txtGreen userCardCol ion-text-justify "
                >
                  {`${order.user.lastName} ${order.user.name}`}
                </IonCol>
                <IonCol
                  size={"2"}
                  className="txtGreen userCardCol ion-text-justify "
                >
                  {`${order.store?.phone}`}
                </IonCol>
                <IonCol
                  size={"3"}
                  className="txtGreen userCardCol ion-text-justify "
                >
                  {`${order.store?.name}`}
                </IonCol>
              </IonRow>
            );
          })}

        {setDataSearch.length &&
          dataSearch.map((order: any, i: number) => {
            return (
              <IonRow key={i} onClick={() => selectOrder(order, i)}>
                {console.info("order", order)}
                <IonCol
                  size={"2"}
                  className="txtGreen userCardCol ion-text-justify "
                >
                  {`${completeCodigo("" + order.codigo)} `}
                </IonCol>
                <IonCol
                  size={"2"}
                  className="txtGreen userCardCol ion-text-justify "
                >
                  {`${order.user.identification}`}
                </IonCol>
                <IonCol
                  size={"3"}
                  className="txtGreen userCardCol ion-text-justify "
                >
                  {`${order?.user?.lastName} ${order?.user?.name}`}
                </IonCol>

                <IonCol
                  size={"2"}
                  className="txtGreen userCardCol ion-text-justify "
                >
                  {`${order?.store?.phone}`}
                </IonCol>
                <IonCol
                  size={"3"}
                  className="txtGreen userCardCol ion-text-justify "
                >
                  {`${order?.store?.name}`}
                </IonCol>
              </IonRow>
            );
          })}
      </div>
      {rol.includes("5.1") && (
        <IonButton className="OrderListBtn" onClick={() => download()}>
          Descargar
        </IonButton>
      )}

      <div className="UserCardBackNotification">
        <IonRow>
          <IonCol
            size={"11"}
            className="userCardCol ion-text-justify UserCardNotificationTxt"
          >
            Notificaciones
          </IonCol>
          <IonCol
            size={"1"}
            className="userCardCol ion-text-center OrderNotificationTxt2"
          >
            {dataNotification.length}
          </IonCol>
        </IonRow>

        <div
          className="OrderListNotificationDiv"
          style={{ overflowY: "scroll" }}
        >
          {dataNotification.map((order: any, i: number) => {
            return (
              <div key={(i + 1) * 10}>
                <IonRow>
                  <IonCol
                    size={"2"}
                    className="userCardCol ion-text-justify txtGreen"
                    onClick={() => selectOrder(order, i)}
                  >
                    {`${completeCodigo("" + order.codigo)}`}
                  </IonCol>
                  <IonCol
                    size={"2"}
                    className="userCardCol ion-text-justify txtGreen"
                    onClick={() => selectOrder(order, i)}
                  >
                    {`${order.user.identification}`}
                  </IonCol>
                  <IonCol
                    size={"3"}
                    className="userCardCol ion-text-justify txtGreen"
                    onClick={() => selectOrder(order, i)}
                  >
                    {`${order.user.lastName} ${order.user.name}`}
                  </IonCol>
                  <IonCol
                    size={"2"}
                    className="userCardCol ion-text-justify txtGreen"
                    onClick={() => selectOrder(order, i)}
                  >
                    {`${order.store?.phone}`}
                  </IonCol>
                  <IonCol
                    size={"3"}
                    className="userCardCol ion-text-justify txtGreen"
                    onClick={() => selectOrder(order, i)}
                  >
                    {`${order.store?.name}`}
                  </IonCol>
                </IonRow>
              </div>
            );
          })}
        </div>
      </div>
    </IonGrid>
  );
}
