import { 
  IonContent,
  IonPage,
  IonLoading,
  IonGrid,
  IonRow,
  IonCol,
  IonPopover,
} from '@ionic/react';

import React, { useState, useEffect } from 'react';

import './Product.css'

import { Header } from '../../components/Header/Header';

import { ProductFilter } from './ProductFilter';
import { Products } from './Products';
import { ProductForm } from './ProductForm';
import { getType, getProductsBySubCategoryStatus } from '../../api/type/type';
import { putProduct, putPolitica } from '../../api/products/products';
import dayjs from 'dayjs';
import { getLocalUser } from '../../api/user/user';

const dataStatus = [
  {
    id: '0',
    name:'Todos'
  },{
    id: '1',
    name:'Activos'
  },{
    id: '2',
    name:'Inactivos'
  }
]
const Product: React.FC<any> = (props) => {
  
  const [showLoading, setShowLoading] = useState(true);
  const [showModalPresentation, setShowModalPresentation] = useState(false);

  const [render, setRender] = useState(true);
  //const [data, setData] = useState<any>([]);

  const [categories, setCategories] = useState<any>([]);
  const [category, setCategory] = useState('');
  const [subCategories, setSubCategories] = useState<any>([]);
  const [subCategory, setSubCategory] = useState('');
  const [products, setProducts] = useState<any>([]);
  const [product, setProduct] = useState('');
  const [objProduct, setObjProduct] = useState<any>({});
  
  const [dataSearch, setDataSearch] = useState<any>([]);
  const [search, setSearch] = useState<any>('');

  const [status, setStatus] = useState('0');

  const [rol, setRol] = useState('');

  const preSetSearch = (v: string) => {
    
    if(v === '') {
      setSearch(v)
      return
    }

    let newData:any[] = []

    products.forEach( (obj: any) => {
      
      const filter = obj.name.toUpperCase() + ' ' + obj.content.toUpperCase() 
      const words = v.toUpperCase().split(' ')
      let flag = true
      words.forEach((word: any, i: number) => {
        if(!flag)
          return
  
        if(!filter.includes(word)){
          flag = false
        }
  
        if(flag && i === (words.length-1)){
          if(!newData)
          newData = [obj]
        else 
          newData.push(obj)
        }
      });
      
    });

    setDataSearch(newData)
    
    setSearch(v)
  }

  const fetchData = async () => {
    try {
    setShowLoading(true);
    //const result = await getAllUsu();
  
    if(!categories.length){
      const result = await getType('2');
      
      setCategories(result.data)
      setCategory(result.data[0].id)
    }
      
    setShowLoading(false);

    } catch (error) {
      console.log("errrorr", error)
    }

  }

  const fetchSubCategory = async () => {
    
    if(!category)
      return
    try {
    setShowLoading(true);
    
    const result = await getType(category); 
    
      setSubCategories(result.data)
      
      setSubCategory(result.data[0].id)
    setShowLoading(false);

    } catch (error) {
      console.log("errrorr", error)
    }

  }

  const fetchPolitica = async (ids: any[]) => {
    setShowLoading(true);
    const obj = {
      quantity: objProduct.quantity,
      start: objProduct.start,
      end: objProduct.end,
      orderQuantity: objProduct.orderQuantity,
      ids
    }
    await putPolitica(obj); 
    await fetchProducts()
    setShowLoading(false);
  }
  
  const changePolitica = () => {

    const data = search !== '' ? dataSearch : products
    let ids: any[] = []
    data.forEach((element: any) => {
      ids.push(element.id)
    });
    
    fetchPolitica(ids)
  }
  
  const fetchProducts = async () => {
    
    if(!subCategory)
      return

    try {
    setShowLoading(true);
    
    const result = await getProductsBySubCategoryStatus(subCategory, status); 
    
    setProducts(result.data)
    if(result.data.length){
      setProduct(result.data[0].id)
      setObjProduct(result.data[0])
    }else {
      setProduct('')
      setObjProduct({ })
    }
    setShowLoading(false);

    } catch (error) {
      console.log("errrorr", error)
    }

  }
  
  const saveProduct = async (newProduct: any, indexPresentation: any, usePrice: any) => {
    try {
      setShowLoading(true);

      const prod = {
        ...newProduct,
        start: dayjs(newProduct.start),
        end: dayjs(newProduct.end),
      }
      
      const presentations = [
        ...prod.presentations
      ]
      presentations[indexPresentation] = {
        ...presentations[indexPresentation],
        usePrice
      }
      prod.presentations=[
        ...presentations
      ]

      await putProduct(prod); 

      const i = products.findIndex( (element: any) => element.id === prod.id)
      products[i] = prod

      setProducts(products)
      setObjProduct(products[0])
      setRender(!render)

      setShowLoading(false);
  
      } catch (error) {
        console.log("errrorr", error)
      }
  }

  useEffect(() => {
    fetchData()
    getLocalUser().then( (res2: any) => {
      console.info('res2', res2)
      setRol(""+res2.idRole)
    })
  }, [])

  useEffect(() => {
    
    fetchSubCategory()
  }, [category])
  
  useEffect(() => {
    fetchProducts()
  }, [subCategory, status])

    const changePresentation = (i: number) => {
    objProduct.iPresentation = i
    setRender(!render)
    setShowModalPresentation(false)
  }
console.info("catalogo")
  /*
  const logOut = () => {
    setShowLoading(true)
    console.log("out")
    removeSesionData().then( () => {
      props.history.push('/login')
      setShowLoading(false)
    })
    
  }
  */

  return (
    <IonPage>
      <IonContent className="">
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={'Please wait...'}
      />

      <IonPopover
        isOpen={showModalPresentation}
        onDidDismiss={e => setShowModalPresentation(false)}
      >
        {
          ((objProduct || {}).presentations || []).map( (obj:any, indPresentation: number) => 
          <div key={indPresentation} onClick={() => { changePresentation(indPresentation)}}>
            
            <IonGrid>
              <IonRow className="presentatioRow">
                <IonCol size={'12'}>
                  {obj.detail}   
                </IonCol>
              </IonRow>
            </IonGrid>
            
          </div>
          )
        }
      </IonPopover>
      
      <Header
        selected={'2'}
        modulo={'Catálogo'}
        props={props}
        rol={rol}
      />



      <div>
        <IonGrid>
          <IonRow>
            <IonCol size={'7'}>
              
              <IonGrid>
              <IonRow>
                  <IonCol >
                    <ProductFilter
                      rol={rol}
                      setShowLoading={()=>{}}
                      setCategory={setCategory}
                      dataCategory={categories}
                      valueCategory={category}
                      setSubCategory={setSubCategory}
                      dataSubCategory={subCategories}
                      valueSubCategory={subCategory}
                      search={search}
                      setSearch={preSetSearch}
                      changePolitica={changePolitica}
                      dataStatus={dataStatus}
                      setStatus={setStatus}
                      status={status}
                    />
                  </IonCol> 
                </IonRow>

                <IonRow>
                  <IonCol className={'products'} style={{ overflowY: 'scroll'}}>
                    <Products
                      data={search !== '' ? dataSearch : products}
                      setData={setProducts}
                      setShowModal={setShowModalPresentation}
                      setObjProduct={setObjProduct}
                    />
                  </IonCol> 
                </IonRow>

              </IonGrid>
            
            </IonCol>  
            
            <IonCol size={'5'}>
              <IonRow>
                  <IonCol className={'products2'} style={{ overflowY: 'scroll'}}>
                    <ProductForm
                      product={{...objProduct}}
                      saveProduct={saveProduct}
                      rol={rol}
                    />
                  </IonCol> 
                </IonRow>
            </IonCol>  

          </IonRow>
            
        </IonGrid>
      </div>
      
      </IonContent>
    </IonPage>
  );
    
  }


export default Product;
