export const BACK = require('../img/back.png');
export const LOGO = require('../img/logo.png');
export const CARD = require('../img/card.png');
export const EMAIL = require('../img/email.png');
export const LOCATION = require('../img/location.png');
export const LOCK = require('../img/lock.png');
export const STORE = require('../img/store.png');
export const USER = require('../img/user.png');
export const MAP = require('../img/map.png');
export const PHONE = require('../img/phone.png');
export const NEXT = require('../img/next.png');
export const LUPA = require('../img/lupa.png');
export const VOZ = require('../img/voz.png');
export const CBB = require('../img/cbb.png');
export const CBB2 = require('../img/cbb2.png');
export const PROMOTION = require('../img/promotion.jpeg');
export const PULGARARRIBA = require('../img/pulgar1.png');
export const PULGARARRIBAVERDE = require('../img/pulgar11.png');
export const PULGARABAJO = require('../img/pulgar2.png');
export const UPLOAD = require('../img/upload.png');
export const ICONLASTORDER = require('../img/Ultimopedido2.png');
export const CONFIRMAR = require('../img/si.png');
export const NEGAR = require('../img/no.png');
export const ICONVALIDARPEDIDO = require('../img/validarPedido.png');
export const PEDIDOCONFIRMADO = require('../img/pedidoConfirmado.png');
export const SOPORTEADJUNTAR = require('../img/soporteAdjuntar.png');
export const TIMER = require('../img/timer.png');
export const DESABAJO = require('../img/desAbajo.png');
export const DESDERECHA = require('../img/desDerecha.png');
export const DESARRI = require('../img/desAr.png');
export const SOLCONFIRMADO = require('../img/solConfirmado.png');
export const CERRARBUSCAR = require('../img/cerrarBuscar.png');
export const BUSCARCATALOGO = require('../img/buscarCatalogo.png');
export const ADJUNTO = require('../img/adjunto.png');
export const CERRARSESION = require('../img/cerrarSesion.png');
export const MISORDENES = require('../img/misOrdenes.png');
export const MIPERFIL = require('../img/miPerfil.png');
export const TAB11 = require('../img/user.png');
export const TAB1 = require('../img/userVerde.png');
export const TAB22 = require('../img/catalogo.png');
export const TAB2 = require('../img/catalogoVerde.png');
export const TAB33 = require('../img/rapida.png');
export const TAB3 = require('../img/rapida2.png');
export const TAB44 = require('../img/Zonas.png');
export const TAB4 = require('../img/Zonasverde.png');
export const TAB55 = require('../img/Ordenes.png');
export const TAB5 = require('../img/Ordenesverde2.png');
export const TAB77 = require('../img/logistica.png');
export const TAB7 = require('../img/logisticaVerde.png');

export const TAB66 = require('../img/publicidad.png');
export const TAB6 = require('../img/publicidadVerde.png');
export const SUPPORT = require('../img/ayuda.png');
export const SUPPORT2 = require('../img/ayudaVerde.png');
export const TIENDAROJA = require('../img/tiendaRoja.png');
export const TIENDAVERDE = require('../img/tiendaVerde.png');
export const SOLICIVERDE = require('../img/solicitudesVerde.png');
export const SOLICIROJA = require('../img/solicitudesRoja.png');
export const ORDENESROJA = require('../img/ordenesRoja.png');
export const ORDENESVERDE = require('../img/ordenesVerde.png');
export const DOCADJ = require('../img/docAdj.png');
export const TRASH = require('../img/trash.png');
export const ONLYCANASTA = require('../img/canasta.png');
export const REFRESH = require('../img/refresh.png');
export const SAAVEE = require('../img/save.png');
export const PESOS = require('../img/pesos.png');
export const PUSHPROMO = require('../img/pushPromo.png');
export const Salir = require('../img/salir.png');
