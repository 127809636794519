import axios from "axios";

export function getAxio() {
  return axios.create({
    //baseURL: `https://dev.sanapp.info:3003/api/v1/`,
    //baseURL: `https://api-san-andres.vercel.app/api/v1/`,
    //baseURL: `http://localhost:3005/api/v1/`,
    baseURL: `https://api.sanapp.info/api/v1/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem("token"),
    },
  });
}
