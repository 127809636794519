import { 
  IonContent,
  IonPage,
  IonLoading,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';

import React, { useState, useEffect } from 'react';

import './Zone.css'
import { Header } from '../../components/Header/Header';
import { Toast } from '../../components/Alert/Alert';
import { Map } from '../../components/Map/Map';
import { InputSelect, Input } from '../../components/Inputs/Input';
import { getAllZone } from '../../api/zone/zone';
import { getAllStore } from '../../api/store/store';
import { getLocalUser } from '../../api/user/user';

/*
https://www.npmjs.com/package/geolib
point in poligone revisar
*/

const Zone: React.FC<any> = (props) => {
  
  const [showLoading, setShowLoading] = useState(true);
  //const [data, setData] = useState<any>([]);
  const [dataZona, setDataZona] = useState<any>([]);
  const [zona, setZona] = useState<any>({});

  const [center_, setCenter_] = useState<any>({});
  

  const [dataStore, setDataStore] = useState<any>([]);
  
  const [toast, setToast] = useState(false);
  const [mensaje, setMensaje] = useState('');
  
  
  const [rol, setRol] = useState<any>('');

  const fetchData = async () => {
    try {
      setShowLoading(true)
      const result = await getAllZone();
      console.log("result.data-", result.data)
      setDataZona(result.data)
      setZona(result.data[0])
      setShowLoading(false)
    } catch (error) {
      console.log("errrorr", error)
    }

  }

  const fetchDataStore = async () => {
    try {
      setShowLoading(true)
      const resultStore = await getAllStore(zona.id);
      console.log("result.data-store", resultStore)
      setDataStore(resultStore.data)
      setCenter_(resultStore.centerPoligone)
      setShowLoading(false)
    } catch (error) {
      console.log("errrorr", error)
    }

  }

  const changeZona = (idZona: string) => {
    const i = dataZona.findIndex( (zona : any) => zona.id === idZona)
    setZona(dataZona[i])
  }
  
  
  useEffect(() => {
    console.log("zona", zona)
    fetchDataStore()
    
    fetchData()
    getLocalUser().then( (res2: any) => {
      console.info('res2', res2)
      setRol(""+res2.idRole)
    })
  }, [zona])

  
  useEffect(() => {
    fetchData()

  }, [])

  return (
    <IonPage>
      <IonContent className="">
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={'Please wait...'}
      />

      <Header
        selected={'4'}
        modulo={'Zonas'}
        props={props}
        rol={rol}
      />

      {Toast( {
        message: mensaje,
        open: toast,
        onDissmis: () => { setToast(false)},
        showCloseBtn: true,
        duration: 3000,
        position:'bottom',
        }
      )}

      <IonGrid>
        <IonRow>
        <IonCol size={'2'}>
            <InputSelect
              txt="Zona"
              onInput={(v: string) => changeZona(v)}
              data={dataZona}
              positionTxt={"floating"}
              value={zona.id}
              propId={'id'}
              propTxt={'name'}
            />
          </IonCol>
          <IonCol size={'2'}>
            <Input
                txt="Cantidad de tiendas"
                onInput={(value: string) => {}}
                value={dataStore.length || 0}
                positionTxt={"floating"}
                type={'text'}
                disabled
            />
          </IonCol>
        </IonRow>
      </IonGrid>

      <div className={'minMap'}>
        <Map
          storeId={''}
          loading={setShowLoading}
          setAddres={() =>{}}
          setCity={()=>{}}
          setDepartment={()=>{}}
          setLocality={()=>{}}
          setNeighborhood={()=>{}}
          setLatitude={()=>{}}
          setLongitude={()=>{}}
          className={true}
          polygone_={true}
          pathsPoligone={zona.zoneDets || []}
          dataMarker={dataStore}
          center_={center_}
        />
      </div>  
      
      </IonContent>
    </IonPage>
  );
    
  }


export default Zone;
