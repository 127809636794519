import { IonGrid, IonRow, IonCol, IonImg, IonAlert } from "@ionic/react";
import React, { useState } from "react";

import "./Promotion.css";
import { PUSHPROMO } from "../../const/imgs";
import { Toast } from "../../components/Alert/Alert";
import { sendPushPromo } from "../../api/promotion/promotion";

interface PropPromotions {
  data: any[];
  setShowModal: Function;
  setObjPromotion: Function;
}

export function Promotions({ data, setObjPromotion }: PropPromotions) {
  const [toast, setToast] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [confirmar, setConfirmar] = useState(false);
  const [objPromo, setObjPromo] = useState<any>({});

  const pushPromo = async () => {
    await sendPushPromo(objPromo);
    setMensaje("Se ha enviado la notificación de la promo" + objPromo.name);
    setToast(true);
  };

  return (
    <IonGrid>
      <IonAlert
        isOpen={confirmar}
        onDidDismiss={() => setConfirmar(false)}
        header={"Confirmación"}
        message={` Esta seguro de enviar esta notificación`}
        buttons={[
          {
            text: `No`,
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              console.log("Confirm Cancel: blah");
            },
          },
          {
            text: "Sí",
            handler: () => {
              pushPromo();
            },
          },
        ]}
      />
      {Toast({
        message: mensaje,
        open: toast,
        onDissmis: () => {
          setToast(false);
        },
        showCloseBtn: true,
        duration: 3000,
        position: "bottom",
      })}
      <IonRow>
        {data.map((promotion: any, index: number) => {
          return (
            <IonCol size={"4"} onClick={() => setObjPromotion(promotion)} key={index}>
              <IonGrid onClick={() => setObjPromotion(promotion)}>
                <IonRow>
                  <IonCol className="ion-text-center promotionImg">
                    <IonImg className="" src={promotion.img} />
                  </IonCol>
                </IonRow>
                <IonRow className="ion-text-center">
                  <IonCol size={"12"}>
                    <div className="ion-text-center promotionName">
                      {promotion.name}
                    </div>
                  </IonCol>
                  <IonCol size={"12"}>
                    <div className={"promotionDetail"}>
                      {promotion.description}
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonImg
                      className="productsImgSuggested"
                      src={PUSHPROMO}
                      onClick={() => {
                        setConfirmar(true);
                        setObjPromo(promotion);
                      }}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          );
        })}
      </IonRow>
    </IonGrid>
  );
}
