import { getAxio } from '../api';

const path = "cardinales/"

export function getAllCardinalidades(){
    return getAxio().get(path + 'all/-1').then((res:any) => {
        return res.data
     } ) 
}

export function getAllCardinalidadesByDate(date: string){
    return getAxio().get(path + 'fecha/' + date).then((res:any) => {
        return res.data
     } ) 
}

export function putCardinalidades(data:any){
    return getAxio().put(path, data).then((res:any) => {
        return res.data
    })
}
