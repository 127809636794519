import { 
  IonContent,
  IonPage,
  IonLoading,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/react';

import React, { useState, useEffect } from 'react';

import './Notification.css'

import { Header } from '../../components/Header/Header';

import { Notifications } from './Notifications';
import { NotificationForm } from './NotificationForm';
import { getLocalUser } from '../../api/user/user';
import { getAllNotifications, createNotification, putNotification } from '../../api/notification/notification';

const Product: React.FC<any> = (props) => {
  const [showLoading, setShowLoading] = useState(true);
  const [notifications, setNotifications] = useState<any>([]);
  const [notification, setNotification] = useState<any>({});
  const [rol, setRol] = useState('');

  const fetchData = async () => {
    try {
    setShowLoading(true);
    const result = await getAllNotifications();
    setNotifications(result.data)
    setShowLoading(false);
    } catch (error) {
      console.log("errrorr", error)
    }
  }

  const saveNotification = async (newProduct: any) => {
    try {
      setShowLoading(true);
      if(newProduct.id){
        await putNotification(newProduct)
      }else {
        await createNotification(newProduct)
      }
      setShowLoading(false);
      } catch (error) {
        console.log("errrorr", error)
      }
  }

  useEffect(() => {
    fetchData()
    getLocalUser().then( (res2: any) => {
      setRol(""+res2.idRole)
    })
  }, [])

  const setNotification2 = (x: any) => {
    setNotification(x)
  }

  return (
    <IonPage>
      <IonContent className="">
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={'Please wait...'}
      />
      <Header
        selected={'6'}
        modulo={'Publicidad'}
        props={props}
        rol={rol}
      />
      <div>
        <IonGrid>
          <IonRow>
            <IonCol size={'7'}>
              <IonGrid>
                <IonRow>
                  <IonCol className={'notifications'} style={{ overflowY: 'scroll'}}>
                    <Notifications
                      data={notifications}
                      setNotification={setNotification2}
                      setShowLoading={setShowLoading}
                    />
                  </IonCol> 
                </IonRow>
              </IonGrid>
            </IonCol>  
            <IonCol size={'5'}>
              {/*
                <IonRow>
                  <IonCol size={''} onClick={() => setNotification({})}>
                    Nuevo
                  </IonCol>
                </IonRow>
              */}
              <IonRow>
                  <IonCol className={'products2'} style={{ overflowY: 'scroll'}}>
                    <NotificationForm
                      notification={notification}
                      saveNotification={saveNotification}
                    />
                  </IonCol> 
                </IonRow>
            </IonCol>  

          </IonRow>
            
        </IonGrid>
      </div>
      
      </IonContent>
    </IonPage>
  );

  }


export default Product;
