import React, { useState, useEffect,  } from 'react';
import { IonGrid, IonRow, IonCol, IonTextarea, IonButton, IonImg, IonRippleEffect, IonSlides, IonSlide, IonModal, IonAlert } from '@ionic/react';
import { InputSelect } from '../../components/Inputs/Input';
import { completeCodigo, getStatus, TYPE, getDays } from '../../const/functions';

import { DESDERECHA, DESABAJO, ADJUNTO } from '../../const/imgs';
import { putModification, putModificationClean } from '../../api/orderModification/orderModification';
import dayjs from 'dayjs';
import { orderFinally, preOrder } from '../../api/pedido/pedido';
import { Toast } from '../../components/Alert/Alert';
import { ModalDocument } from '../../components/UserCard/UserCard';
import { getLocalUser } from '../../api/user/user';

interface PropOrderDetail {
  order: any
  status: any[]
  setRenderFather: Function
  loading: Function
  setAllOrder: Function
  allOrder: any[],
  modal: boolean,
  setModal: Function,
  setOrder: Function,
  rol: string
}

export function OrderDetail ( {
  order,
  status,
  setRenderFather,
  loading,
  setAllOrder,
  allOrder,
  setModal,
  modal,
  setOrder,
  rol
} : PropOrderDetail){
  
  const TIMES = [
    { txt: '6 - 8 am', min: 6, max: 8},
    { txt: '8 - 10 am', min: 8, max: 10},
    { txt: '10 - 12 am', min: 10, max: 12},
    { txt: '12 - 2 pm', min: 12, max: 14},
    { txt: '2 - 4 pm', min: 14, max: 16}
  ]

  
  const [modalReplica, setModalReplica] = useState(false);
  const [render, setRender] = useState(true);
  const [toast, setToast] = useState(false);
  const [mensaje, setMensaje] = useState('');
  
  const [modalAdj, setModAdj] = useState(false);
  const [adj, setAdj] = useState<any>({});

  const [changeStatus, setChangeStatus] = useState(false);
  const [statusSelected, setStatusSelected] = useState('');
  
  const days = getDays(5)

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    slidesPerView: 3.5
  };

  const clickUpdOrder = async () => {
    
    loading(true);
    if(order.idPaymentMethod === TYPE.CONTRAENTREGA) order.idStatus = TYPE.CONFIRMADO
    order.deliveryWindow = order.date.format("YYYY-MM-DD") + ' ; ' + order.time.txt
    //console.log(order.date, order.time)
    order.promisedDeliveryDate = order.date.hour(order.time.max + 5).minute(0).second(0).format("YYYY-MM-DD HH:mm:ss") 

    const i = allOrder.findIndex( (item: any) => item.id === order.id)
    if(allOrder[i].idPaymentMethod === TYPE.CONTRAENTREGA && order.idPaymentMethod === TYPE.CONSIGNACION){
      order.idStatus = TYPE.PENDIENTE
    }
    //console.log('deliver', order.promisedDeliveryDate)
    await fetchPutOrder()
    setMensaje('Actualizado correctamente')
    setToast(true)
    
    loading(false);
  }

  const openModification = (modification: any) => {
    modification.open = !modification.open
    setRender(!render)
    setRenderFather()
  }

  const ruleStatus = () => {
    let newData: any[] = []
    status.forEach(element => {
      if(element.name !== 'Todos')
        newData.push(element)
    });
    return newData
  }

  const add = (det: any) => {
    det.quantity += 1
    order.totalAmount += det.idProduct ? det.presentation.price : det.promotion.price
    order.discountAmount += det.idProduct ? 0 : det.promotion.discount
    setRender(!render)
    setRenderFather()
  }
  
  const discount = (det: any) => {
    if(det.quantity>0){
      det.quantity -= 1
      order.totalAmount -= det.idProduct ? det.presentation.price : det.promotion.price
      order.discountAmount -= det.idProduct ? 0 : det.promotion.discount
    }
    setRender(!render)
    setRenderFather()
  }

  const changeMethodPay = (idMethodPay: string) => {
    order.idPaymentMethod = idMethodPay
    
    setRender(!render)
    setRenderFather()
  }
  
  const changeTime = (time: any) => {
    order.time = time
    setRender(!render)
    setRenderFather()
  }
  const changeDay = (day: any) => {
    order.date = day
    setRender(!render)
    setRenderFather()
  }
  

  const cleanOrder = async () => {
    try {
    loading(true);
    console.info("order", order)
    const newOrder = {
      ...order,
      resInt: null,
      idStatus: TYPE.CREADO,
      idUser: "000159",
      idStore: "00026628-b102-4a8f-a8c8-aa882fdfgdfg"
    }
    await putModificationClean(newOrder)
    console.info("--**--************************-")
    loading(false);

    } catch (error) {
      console.log("errrorr", error)
    }

  }

  const preCleanOrder= () => {
    setModalReplica(true)
  }


  const fetchPutModification = async (modification: any) => {
    try {
    loading(true);
    
    if(!modification.answer){
      setMensaje('Debe redactar una respuesta')
      setToast(true)
      loading(false);
      return
    }
    

    modification.idStatus = TYPE.RESUELTO
    const res = await putModification({...modification})
    console.log(res.data, ' --------')
      //modification = res.data

      
console.log("order",order)
    const i = allOrder.findIndex( (item: any) => item.id === order.id)
    console.log("i", i)
    allOrder[i] = order
    setAllOrder(allOrder)
      setRender(!render)
      setRenderFather()
      console.log("allOrder[i]", allOrder[i])
      setOrder({...allOrder[i]})
      //setRefreshAllOrder(order)
      loading(false);



    

    } catch (error) {
      console.log("errrorr", error)
    }

  }

  const cambiarStatus = (idStatus: any) => {
    console.log('1',idStatus)
    if(idStatus === TYPE.ENTREGADO){
      if(order.idStatus !== TYPE.CONFIRMADO){
        console.log("no se puede")
      }else {
        order.idStatus = idStatus; 
        setChangeStatus(true)
        setStatusSelected(idStatus)
      }
    }
    if(idStatus === TYPE.CANCELADO){
      console.log('12222222',idStatus)
      order.idStatus = idStatus; 
      setChangeStatus(true)
      setStatusSelected(idStatus)
    }
    
    if(idStatus === TYPE.ENTREGADO){
      console.log('122222223333',idStatus)
      order.idStatus = idStatus; 
      setChangeStatus(true)
      setStatusSelected(idStatus)
    }
  }
  
  const fetchPutOrder = async () => {
    try {
    //setShowLoading(true);
    
      console.log("idddd", changeStatus, order, changeStatus)
    const res = await orderFinally({...order, deliveryDate: dayjs(), changeStatus, idStatus: statusSelected ? statusSelected : order.idStatus});
    order.detail.forEach( async (det:any) => {
      const isPromo = det.idPromotion ? true : false
      
      await preOrder(det, 1, isPromo)

      console.log("isPromo", isPromo)
      //await preOrder(det, 1, isPromo)
    });
      console.log(res.data)
      setRender(!render)
    //setShowLoading(false);

    } catch (error) {
      console.log("errrorr", error)
    }

  }

  const showAdj = (adj: any) => {
    setModAdj(true)
    setAdj(adj)
  }
  
  return (
    <div className={'OrderDetail'}>

      <IonModal isOpen={modalAdj} onDidDismiss={ () => setModAdj(false)}>
        <ModalDocument
          numDoc={-1}
          obj={{}}
          render={true}
          setRender={() =>{}}
          setShowLoading={loading}
          type={2}
          support={adj}
        />
      </IonModal>
      
      <IonAlert
        isOpen={modal}
        onDidDismiss={() => setModal(false)}
        header={'Confirmación'}
        
        message={ ` Esta seguro de <strong> actualizar </strong> la orden  <strong>${ completeCodigo(''+order.codigo) } </strong>`}
        buttons={[
          {
            text: `No`,
            role: 'cancel',
            cssClass: 'secondary',
            handler: blah => {
              console.log('Confirm Cancel: blah');
            }
          },
          {
            text: 'Sí',
            handler: () => {
              clickUpdOrder()
            }
          }
        ]}
      />

      <IonAlert
        isOpen={modalReplica}
        onDidDismiss={() => setModal(false)}
        header={'Confirmación'}
        
        message={ ` Confirma re enviar la replica ?`}
        buttons={[
          {
            text: `No`,
            role: 'cancel',
            cssClass: 'secondary',
            handler: blah => {
              console.log('Confirm Cancel: blah');
            }
          },
          {
            text: 'Sí',
            handler: () => {
              cleanOrder()
            }
          }
        ]}
      />

              
      {Toast( {
        message: mensaje,
        open: toast,
        onDissmis: () => { setToast(false)},
        showCloseBtn: true,
        duration: 3000,
        position:'bottom',
        }
      )}
        
      <IonGrid>
        <IonRow className='orderDetailTxt1'>
          <IonCol size={'6'}>
            Orden
          </IonCol>
          <IonCol size={'6'} className='ion-text-end'>
            # {completeCodigo(''+order.codigo)}
          </IonCol>
        </IonRow>
      </IonGrid>
      
      {
      <IonButton className='btnEnviar' onClick={() => preCleanOrder()}>
        Reenviar replica
      </IonButton>
      }
      <IonGrid >
        <IonRow>
          <IonCol size={'12'} >
            
          </IonCol>
        </IonRow>
      <IonRow>
          <IonCol size={'6'} className='orderTxtRed'>
            {console.info('order.idStatusorder.idStatus', order.idStatus)}
            Estado: {getStatus(order.idStatus)}
          </IonCol>
          <IonCol size={'6'}>
            <InputSelect
              txt="Estado"
              onInput={(idStatus:string) => { cambiarStatus(idStatus)}}
              data={ruleStatus()}
              positionTxt={"floating"}
              value={order.idStatus}
              propId={'id'}
              propTxt={'name'}
              className=' '
            />
          </IonCol>
        </IonRow>

        
        
        {(order.modification || []).map( (modification:any, i: number) => {
          return (
            <IonGrid className={modification.idStatus === TYPE.RESUELTO ? 'orderDetailSolicitudLista' : 'orderDetailSolicitud'} key={i} >
              <IonRow onClick={() => openModification(modification) } className='orderDetailSolTxtRed'>
                <IonCol size={'5'}>
                  Solicitud #{completeCodigo(''+modification.codigo)}
                </IonCol>
                <IonCol size={'6'}>
                  Tipo {getStatus(modification.idType)}
                </IonCol>
                <IonCol size={'1'}>
                  <IonImg
                  className={'OrderDetIconDesAbajo'}
                    src={modification.open ? DESABAJO : DESDERECHA}
                  />
                </IonCol>
                
              </IonRow>
              {modification.open &&
                <div className='orderDetailSolTxtRed'>
                  <IonRow>
                    <IonCol size={'6'}>
                      Fe  cha
                    </IonCol>
                    <IonCol size={'6'}>
                    {dayjs( modification.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol size={'6'}>
                      Detalle
                    </IonCol>
                    <IonCol size={'6'}>
                      { modification.idType === TYPE.CAMBIARFECHAYHORA &&
                          <div>
                            cambio a {modification.deliveryWindow}
                          </div>
                      }
                      { modification.idType === TYPE.CAMBIARPRODUCTOS &&
                          <div>
                            {
                              modification.orderModificationDetails.map((prod: any, i: number) => 
                              <IonRow key={i}>
                                <IonCol size={'10'} className='solicitudesTxt'>
                                  {prod.name}
                                </IonCol>
                                <IonCol size={'2'} className='solicitudesTxt'>
                                  {prod.quantity}
                                </IonCol>
                              </IonRow>
                              )
                            }
                          </div>
                      }
                      { modification.idType === TYPE.CAMBIARMETODODEPAGO &&
                          <div>
                            cambio a {modification.idPaymentMethod === TYPE.CONTRAENTREGA ? 'Contra entrega' : 'Consignación'}
                          </div>
                      }
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      comentario
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      {modification.commentary}
                    </IonCol>
                  </IonRow>

                  { modification.support &&
                    <IonRow>
                      <IonCol size={'11'}>
                      </IonCol>
                      <IonCol size={'1'} onClick={() => showAdj(modification)}>
                        <IonImg src={ADJUNTO} />
                      </IonCol>
                    </IonRow>
                  }
                  
                  <IonRow>
                    <IonCol size={'12'} className=''>
                      Respuesta
                    </IonCol>
                    { modification.idStatus !== TYPE.RESUELTO &&
                      <IonCol size={'12'} className=''>
                        <IonCol size={'12'} className=''>
                          {
                            <IonTextarea
                              placeholder={'Respuesta'}
                              value={modification.answer}
                              onIonChange={(e: any) => modification.answer = e.detail.value}
                              className='orderRow'
                              rows={2}
                            />
                          }
                        </IonCol>

                        { rol.includes("5.2") &&
                          <IonCol size={'12'} className=''>
                            <IonButton className='btnEnviar' onClick={() => fetchPutModification(modification)}>
                              Enviar
                            </IonButton>

                          </IonCol>
                        }
                        
                      </IonCol>
                    }
                    { modification.idStatus === TYPE.RESUELTO &&
                      <IonCol size={'12'} className=''>
                        {modification.answer}
                      </IonCol>
                    }
                </IonRow>
                </div>
              }
            </IonGrid>
          )
        })}

        <IonGrid className='OrderDetailDivProduc'>
          <IonRow>
            <IonCol>
              Productos
            </IonCol>
          </IonRow>
          {(order.detail || []).map( (det:any, i:number) => {
            return (
              <IonRow key={i}>
                <IonCol size={'2'}>
                  <IonImg
                    src={det.idProduct ? det.product.img : det.promotion.imgVertical}
                  />
                </IonCol>
                <IonCol size={'4'} className=''>
                  <div className='orderDetailOneLine'>
                    {det.idProduct ? det.product.name : det.promotion.name}
                  </div>
                  <div className='orderDetailOneLine'>
                    {det.idProduct ? det.presentation.detail : ''}
                  </div>
                </IonCol>
                
                <IonCol size={'6'}>
                  <IonGrid>
                    <IonRow className='ion-text-center'>
                      <IonCol size={'3'} onClick={() => discount(det)}>
                        <div>
                          <div className="ion-activatable ripple-parent">
                            <IonRippleEffect className=""></IonRippleEffect>
                            -
                          </div>
                        </div>
                      </IonCol>
                      <IonCol size={'6'}>
                        {det.quantity}
                      </IonCol>
                      <IonCol size={'3'} onClick={() => add(det)}>
                        <div>
                          <div className="ion-activatable ripple-parent">
                            <IonRippleEffect className=""></IonRippleEffect>
                            +
                          </div>
                        </div>
                      </IonCol>
                      
                      
                    </IonRow>
                  </IonGrid>
                </IonCol>
              
              </IonRow>
            )
          })}

          <IonRow>
            <IonCol size={'12'}>
              Método de pago
            </IonCol>
            
            <IonCol
              size="5" 
              onClick={() => {changeMethodPay(TYPE.CONTRAENTREGA)} }
              className={ order.idPaymentMethod === TYPE.CONTRAENTREGA ? "ion-text-center paddingTop deliveryMethodOpc1 deliveryOrderSelected" : "ion-text-center paddingTop deliveryMethodOpc1"} 
            >
              Contra Entrega
            </IonCol>
            
            <IonCol
              size={'2'}
            />

            <IonCol
              size="5" 
              onClick={() => {changeMethodPay(TYPE.CONSIGNACION)} }
              className={ order.idPaymentMethod === TYPE.CONSIGNACION ? "ion-text-center paddingTop deliveryMethodOpc1 deliveryOrderSelected" : "ion-text-center paddingTop deliveryMethodOpc1"} 
            >
              Consignación
            </IonCol>
          </IonRow>
          
          <br/>

          <IonRow className="">
            <IonCol size={'12'}>
              Fecha
            </IonCol>
            <IonCol className="ion-text-center paddingTop " size="12">
            
            <IonSlides pager={false} className="" options={slideOpts}>
              {
                days.map( (day:any, i:number) => (
                  <IonSlide key={i} className={""}>
                    <div
                      key={i}
                      className={
                        order.date ?
                          (order.date.format("DD MMM") || order.date ) === day.txt ? 
                            "deliveryOrderDays deliveryOrderSelected"
                            : "deliveryOrderDays "  
                          : 'deliveryOrderDays'
                      }
                      onClick={() => changeDay(day.value) }
                    >
                      {day.txt}
                    </div>
                  </IonSlide>
                ))
                
              }
            </IonSlides> 
            
            </IonCol>
          </IonRow>

          <br/>
          
          <IonRow className="">
            <IonCol size={'12'}>
              Hora
            </IonCol>
            <IonCol className="ion-text-center paddingTop " size="12">
            
            <IonSlides pager={false} className="" options={slideOpts}>
                {TIMES.map( (time:any, i:number) => (
                  <IonSlide key={i} className={""}>
                    <div
                      key={i}
                      className={ (order.time || {}).min === time.min ? "deliveryOrderDays deliveryOrderSelected" : "deliveryOrderDays  "}
                      onClick={() => changeTime(time) }
                    >
                      {time.txt}
                    </div>
                  </IonSlide>
                ))}
            </IonSlides> 
            
            </IonCol>
          </IonRow>

          { rol.includes('5.3') &&
            <IonButton className='OrderBtn'  onClick={() => setModal(true) }>
                Guardar
            </IonButton>
          }

        </IonGrid>

      </IonGrid>
    </div>
  )
}