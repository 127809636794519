import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonItem,
  IonToggle,
  IonTextarea,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import ImageUploader from "react-images-upload";

import "./NotificationForm.css";
import { Input } from "../../components/Inputs/Input";
import LazyImage from "../../components/lazy-image/lazy-image";

import "./NotificationForm.css";

interface PropNotificationForm {
  notification: any;
  saveNotification: Function;
}

export function NotificationForm({
  notification,
  saveNotification,
}: PropNotificationForm) {
  const ID = notification.id;
  const [txt, setTxt] = useState("");
  const [txtProduct, setTxtProduct] = useState("");
  const [txtPromotion, setTxtPromotion] = useState("");
  const [img, setImg] = useState<any>();
  const [fileImg, setFileImg] = useState<any>();
  const [status, setStatus] = useState(false);

  const onChangeImage = (picture: any) => {
    console.info("picture", picture);
    setFileImg(picture);
    const img = new Image();
    img.src = picture.image_source;
    changeListener(picture);
  };

  const changeListener = (files: FileList) => {
    if (files && files.length > 0) {
      const file: any = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (readerEvent) => {
        const imageData = (readerEvent.target as any).result;
        setImg(imageData);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }
  };

  useEffect(() => {
    setImg("");
    setTxt(notification.txt);
    setTxtProduct(notification.txtProduct);
    setTxtPromotion(notification.txtPromotion);
    setStatus(notification.status);
    setTimeout(() => {
      setImg(notification.img);
    }, 100);
  }, [notification]);

  return (
    <IonGrid>
      
      <IonRow>
        <IonCol className="notificationCenterImg">
          {!img && (
            <ImageUploader
              withIcon={true}
              buttonText="Choose images"
              onChange={onChangeImage}
              imgExtension={[".jpg", ".gif", ".png", ".gif"]}
              maxFileSize={5242880}
              singleImage
            />
          )}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="notificationCenterImg">
          {img && (
            <div>
              {
                <div
                  onClick={() => {
                    setImg("");
                    setFileImg("");
                  }}
                >
                  Limpiar
                </div>
              }
              <LazyImage
                src={img}
                alt={"Image Notification"}
                className="notificationCenterImg"
              />
            </div>
          )}
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol size={"12"}>
          <IonTextarea
            placeholder={"Texto"}
            value={txt}
            onIonChange={(e: any) => setTxt(e.detail.value)}
            className="orderRow"
            rows={3}
          />
        </IonCol>
        <IonCol size={"6"}>
          <Input
            txt="Texto Producto"
            onInput={(value: string) => setTxtProduct(value)}
            value={txtProduct}
            positionTxt={"floating"}
            type={"text"}
          />
        </IonCol>
        <IonCol size={"6"}>
          <Input
            txt="Texto Promotion"
            onInput={(value: string) => setTxtPromotion(value)}
            value={txtPromotion}
            positionTxt={"floating"}
            type={"text"}
          />
        </IonCol>
        <IonCol size={"6"}>
          <IonItem
            lines="none"
            className="productFormBack productFormSuggested"
          >
            Estado
            <IonToggle
              checked={status ? true : false}
              onIonChange={(e) => {
                setStatus(!status);
              }}
            />
          </IonItem>
        </IonCol>
      </IonRow>
      {
        //rol.includes("2.2") &&
        <IonRow>
          <IonButton
            className="productFormSave"
            onClick={() =>
              saveNotification({
                file: fileImg ? fileImg[0] : null,
                id: ID,
                txt,
                txtProduct,
                txtPromotion,
                status: status,
              })
            }
          >
            Guardar
          </IonButton>
        </IonRow>
      }
    </IonGrid>
  );
}
