import {
  IonContent,
  IonPage,
  IonLoading,
  IonGrid,
  IonRow,
  IonCol,
  IonPopover,
} from "@ionic/react";

import React, { useState, useEffect } from "react";

import "./Promotion.css";

import { Header } from "../../components/Header/Header";
import { PromotionFilter } from "./PromotionFilter";
import { Promotions } from "./Promotions";
import { PromotionForm } from "./PromotionForm";
import { getProductSearchTxt } from "../../api/products/products";

import {
  getPromotion,
  putPromotion,
  deletePromotionDetail,
  getPromotionById,
} from "../../api/promotion/promotion";
import { getLocalUser } from "../../api/user/user";

let timeOut: any = null;

const Promotion: React.FC<any> = (props) => {
  const [showLoading, setShowLoading] = useState(true);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [showModalPresentation, setShowModalPresentation] = useState(false);

  const [render, setRender] = useState(true);

  const [promotions, setPromotions] = useState<any>([]);
  const [objPromotion, setObjPromotion] = useState<any>({});

  const [dataSearch, setDataSearch] = useState<any>([]);
  const [search, setSearch] = useState<any>("");

  const [searchProducts, setSearchProducts] = useState<any>("");
  const [dataSearchProducts, setDataSearchProducts] = useState<any>([]);
  const [objProduct, setObjProduct] = useState<any>({});
  const [iObjProduct, setIObjProduct] = useState<any>(-1);

  const [rol, setRol] = useState<any>("");

  const preSetSearch = (v: string) => {
    if (v === "") {
      setSearch(v);
      return;
    }

    let newData: any[] = [];

    promotions.forEach((obj: any) => {
      const filter =
        obj.name.toUpperCase() + " " + obj.description.toUpperCase();
      const words = v.toUpperCase().split(" ");
      let flag = true;
      words.forEach((word: any, i: number) => {
        if (!flag) {
          return;
        }
        if (!filter.includes(word)) {
          flag = false;
        }
        if (flag && i === words.length - 1) {
          if (!newData) {
            newData = [obj];
          } else {
            newData.push(obj);
          }
        }
      });
    });
    setDataSearch(newData);
    setSearch(v);
  };

  const deleteDetail = async (detail: any) => {
    await deletePromotionDetail(detail.id);
    const i = objPromotion.promotionDetails.findIndex(
      (det: any) => det.id === detail.id
    );
    objPromotion.promotionDetails.splice(i, 1);
    setRender(!render);
  };

  const onClickProduct = () => {
    setSearchProducts("");
    setDataSearchProducts([]);
    setRender(!render);
  };

  const preSetSearchProduct = async (v: string) => {
    setSearchProducts(v);
    if (v === "") {
      setDataSearchProducts([]);
      setLoadingSearch(false);
      return;
    }
    setLoadingSearch(true);
    fetGetSearchProduct(v, setDataSearch, setLoadingSearch);
  };

  const fetGetSearchProduct = (
    txt: string,
    setData: Function,
    setLoadingSearch: Function
  ) => {
    clearTimeout(timeOut);

    timeOut = setTimeout(async function () {
      const r = await getProductSearchTxt(txt);
      setDataSearchProducts(r.data);
      setLoadingSearch(false);
      return r;
    }, 500);
  };

  const fetchData = async () => {
    try {
      setShowLoading(true);
      const result = await getPromotion();
      setPromotions(result.data);
      setShowLoading(false);
    } catch (error) {
      console.log("errrorr", error);
    }
  };

  const fetchDataDetail = async (id: string) => {
    try {
      setShowLoading(true);
      const {data} = await getPromotionById(id);
      console.info("data", data)
      setObjPromotion(data)
      setShowLoading(false);
    } catch (error) {
      console.log("errrorr", error);
    }
  };

  const savePromotion = async () => {
    try {
      setShowLoading(true);
      const foor = async () => {
        let newDeta: any = [];
        objPromotion.promotionDetails.forEach((detail: any) => {
          if (detail.product.iPresentation > -1) {
            const d = { ...detail };
            d.idPresentation =
              detail.product.presentations[detail.product.iPresentation].id;
            d.pricePresentation =
              detail.product.presentations[detail.product.iPresentation].price;
            newDeta.push(d);
          }
        });
        if (newDeta.length) {
          objPromotion.promotionDetails = newDeta;
        }
      };

      await foor();
      await putPromotion(objPromotion);
      setObjPromotion({});
      await fetchData();
      setShowLoading(false);
    } catch (error) {
      console.log("errrorr", error);
    }
  };

  useEffect(() => {
    fetchData();
    getLocalUser().then((res2: any) => {
      setRol("" + res2.idRole);
    });
  }, []);

  const changePresentation = (i: number) => {
    setShowLoading(true);
    objProduct.iPresentation = i; //iObjProduct//i
    const iPromo = promotions.findIndex(
      (pro: any) => pro.id === objPromotion.id
    );
    console.info("iPromo", iPromo)
    const iProduc = promotions[iPromo].promotionDetails.findIndex(
      (detail: any) => detail.product.id === objProduct.id
    );
    console.info("iProduc", iProduc)
    if (iObjProduct > -1) {
      promotions[iPromo].promotionDetails[iObjProduct].product = objProduct;
    } else {
      promotions[iPromo].promotionDetails[iProduc].product = objProduct;
    }
    setPromotions(promotions);
    setShowModalPresentation(false);
    setShowLoading(false);
    setRender(!render);
  };

  return (
    <IonPage>
      <IonContent className="">
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
        />
        <IonPopover
          isOpen={showModalPresentation}
          onDidDismiss={(e) => setShowModalPresentation(false)}
        >
          {((objProduct || {}).presentations || []).map(
            (obj: any, indPresentation: number) => (
              <div
                key={indPresentation}
                onClick={() => {
                  changePresentation(indPresentation);
                }}
              >
                <IonGrid>
                  <IonRow className="presentatioRow">
                    <IonCol size={"12"}>{obj.detail}</IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            )
          )}
        </IonPopover>
        <Header selected={"3"} modulo={"Promociones"} props={props} rol={rol} />
        <div>
          <IonGrid>
            <IonRow>
              <IonCol size={"6"}>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <PromotionFilter
                        search={search}
                        setSearch={preSetSearch}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      className={"products"}
                      style={{ overflowY: "scroll" }}
                    >
                      <Promotions
                        data={search !== "" ? dataSearch : promotions}
                        setShowModal={setShowModalPresentation}
                        setObjPromotion={(e: any) => {
                          console.info("la promocion", e)
                          fetchDataDetail(e.id)
                          //setObjPromotion(e);
                        }}
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
              <IonCol size={"6"}>
                <IonRow>
                  <IonCol
                    className={"products2"}
                    style={{ overflowY: "scroll" }}
                  >
                    <PromotionForm
                      rol={rol}
                      promotion={objPromotion}
                      savePromotion={savePromotion}
                      search={searchProducts}
                      setSearch={preSetSearchProduct}
                      searchProducts={dataSearchProducts}
                      onClickProduct={() => onClickProduct()}
                      loadingSearch={loadingSearch}
                      setShowModalPresentation={setShowModalPresentation}
                      deleteDetail={deleteDetail}
                      setObjProduct={setObjProduct}
                      setIObjProduct={setIObjProduct}
                    />
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Promotion;
