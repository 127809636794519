import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonItem,
  IonToggle,
} from "@ionic/react";
import React, { useState, useEffect } from "react";

import "./CalendarForm.css";

import "./CalendarForm.css";

interface PropCalendarForm {
  calendar: any;
  saveCalendar: Function;
}

export function CalendarForm({ calendar, saveCalendar }: PropCalendarForm) {
  const [newCalendar, setNewCalendar] = useState<any>(calendar);

  const hourExist = (hour: string) => {
    const i = newCalendar?.calendarDets?.findIndex(
      (calendarDet: any) => calendarDet.hour === hour
    );
    if (i > -1) {
      return false;
    } else {
      return true;
    }
  };

  const addHour = (obj: any) => {
      const i = newCalendar.calendarDets.findIndex(
        (calendarDet: any) => calendarDet.hour === obj.hour
      );
      if (i > -1) {
        newCalendar.calendarDets.splice(i, 1);
      } else {
        const newNewCalenda = {
          ...newCalendar,
        };
        newNewCalenda.calendarDets.push(obj);
      }
  };

  useEffect(() => {
    setNewCalendar({ ...calendar });
  }, [calendar]);

  return (
    <IonGrid>
      <IonRow>
        <IonCol size={"12"}>
          <div className={"productFormBack calendarFormTxt"}>
            {newCalendar?.dateJs?.format("DD-MM-YYYY")}
          </div>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size={"8"}>
          <div className={"productFormBack calendarFormTxt"}>Horario</div>
        </IonCol>
        <IonCol size={"4"}>
          <div className={"productFormBack calendarFormTxt"}>Laboral</div>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size={"8"}>
          <div className={"productFormBack calendarFormTxt"}>6 - 8 AM</div>
        </IonCol>
        <IonCol size={"4"}>
          <IonItem lines="none" className="productFormBack CalendarFormBack">
            <IonToggle
              checked={hourExist("06:00:00")}
              onIonChange={(e: any) => {
                addHour({
                  hour: "06:00:00",
                  max: 8,
                  min: 6,
                  txt: "6 - 8 am",
                });
              }}
            />
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size={"8"}>
          <div className={"productFormBack calendarFormTxt"}>8 -10 AM</div>
        </IonCol>
        <IonCol size={"4"}>
          <IonItem lines="none" className="productFormBack CalendarFormBack">
            <IonToggle
              checked={hourExist("08:00:00")}
              onIonChange={(e: any) => {
                addHour({
                  hour: "08:00:00",
                  max: 10,
                  min: 8,
                  txt: "8 - 10 am",
                });
              }}
            />
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size={"8"}>
          <div className={"productFormBack calendarFormTxt"}>10 - 12 AM</div>
        </IonCol>
        <IonCol size={"4"}>
          <IonItem lines="none" className="productFormBack CalendarFormBack">
            <IonToggle
              checked={hourExist("10:00:00")}
              onIonChange={(e: any) => {
                addHour({
                  hour: "10:00:00",
                  max: 12,
                  min: 10,
                  txt: "10 - 12 am",
                });
              }}
            />
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size={"8"}>
          <div className={"productFormBack calendarFormTxt"}>12 - 2 PM</div>
        </IonCol>
        <IonCol size={"4"}>
          <IonItem lines="none" className="productFormBack CalendarFormBack">
            <IonToggle
              checked={hourExist("12:00:00")}
              onIonChange={(e: any) => {
                addHour({
                  hour: "12:00:00",
                  max: 14,
                  min: 12,
                  txt: "12 - 2 pm",
                });
              }}
            />
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size={"8"}>
          <div className={"productFormBack calendarFormTxt"}>2 - 4 PM</div>
        </IonCol>
        <IonCol size={"4"}>
          <IonItem lines="none" className="productFormBack CalendarFormBack">
            <IonToggle
              checked={hourExist("14:00:00")}
              onIonChange={(e: any) => {
                addHour({
                  hour: "14:00:00",
                  max: 14,
                  min: 16,
                  txt: "2 - 4 pm",
                });
              }}
            />
          </IonItem>
        </IonCol>
      </IonRow>
      {
        <IonRow>
          <IonButton
            className="productFormSave"
            onClick={() => saveCalendar(newCalendar)}
          >
            Guardar
          </IonButton>
        </IonRow>
      }
    </IonGrid>
  );
}
