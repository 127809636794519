import React, { useState } from 'react';
import { TIENDAROJA } from '../../const/imgs';
import { IonImg, IonGrid, IonRow, IonCol, IonModal } from '@ionic/react';
import { TYPE, completeCodigo, getStatus } from '../../const/functions';
import NumberFormat from 'react-number-format';
import dayjs from 'dayjs';

import { getSupport, orderFinally, preOrder } from '../../api/pedido/pedido';
import { ModalDocument } from '../../components/UserCard/UserCard';

interface PropOrderFinish {
  order: any
  allOrder: any
  loading: Function
  setAllOrder: Function,
  setMensaje: Function,
  setToast: Function
  rol: string
}

export function OrderFinish ( {
  order,
  allOrder,
  loading,
  setAllOrder,
  setMensaje,
  setToast, rol
} : PropOrderFinish){
  
  
  const [modal, setModal] = useState(false);


  const clickRecibo = () => {
    fetchSupport()
  }
  
  const fetchSupport = async () => {
    try {
      loading(true);
      //console.log("allOrderaaa----------")
      if(order.support) {
        setModal(true)
        loading(false);
        //console.log("qqqqq")
        return
      }

      //console.log("allOrderaaa", allOrder)
      const res = await getSupport(order.id);
      //console.log("reeeesss", res.data.support)

      const i = allOrder.findIndex( (item: any) => item.id === order.id )
      order.support = res.data.support
      allOrder[i] = order
      setAllOrder(allOrder)

      
      setModal(true)
      loading(false);

    } catch (error) {
      console.log("errrorr", error)
    }

  }

  const onAceptar = async () => {
    loading(true)

    //Esta funcion fue copiada de orderDetail...
    const fetchPutOrder = async () => {
      try {
      //setShowLoading(true);
      
      const res = await orderFinally({...order, idStatus: TYPE.CONFIRMADO, deliveryDate: dayjs()});
      order.idStatus = TYPE.CONFIRMADO
      order.detail.forEach( async (det:any) => {
        const isPromo = det.idPromotion ? true : false
        
        await preOrder(det, 1, isPromo)

        console.log("isPromo", isPromo)
        //await preOrder(det, 1, isPromo)
      });
        console.log(res.data)
        setModal(false)
        //setRender(!render)
      //setShowLoading(false);

      } catch (error) {
        console.log("errrorr", error)
      }

    }
  
    await fetchPutOrder()
    setMensaje('Recibo aprobado')
    setToast(true)


    loading(false)
    console.log("aceptar")
  }

  
  const onCancelar = async () => {
    loading(true)

    //Esta funcion fue copiada de orderDetail...
    const fetchPutOrder = async () => {
      try {
      //setShowLoading(true);
      
    
      const res = await orderFinally({...order, idStatus: TYPE.PENDIENTE, deliveryDate: dayjs()});
      order.idStatus = TYPE.PENDIENTE
      order.detail.forEach( async (det:any) => {
        const isPromo = det.idPromotion ? true : false
        
        await preOrder(det, 1, isPromo)

        console.log("isPromo", isPromo)
        //await preOrder(det, 1, isPromo)
      });
        console.log(res.data)
        //setRender(!render)
      //setShowLoading(false);
      setModal(false)
      } catch (error) {
        console.log("errrorr", error)
      }

    }
    await fetchPutOrder()
    setMensaje('Recibo Rechazado')
    setToast(true)
  
    loading(false)
    console.log("cancelar")
  }

  return (
    <div className={''}>
      
      <IonModal isOpen={modal} onDidDismiss={ () => setModal(false)}>
        <ModalDocument
          show={true}
          numDoc={-1}
          obj={{}}
          render={true}
          setRender={() =>{}}
          setShowLoading={loading}
          type={2}
          support={order}
          onAceptar={onAceptar}
          onCancelar={onCancelar}
        />
      </IonModal>
      
      <div className={'UserCardUserPresentation'} >
        <IonImg
          src={TIENDAROJA}
          className={'UserCardUserImg'}
        />
        <div className={'UserCardUserTxt'}>
          <div className={'orderFinishTxt'}>
            {`${ (order.user || {}).name}  ${(order.user || {}).lastName }`}
          </div>
          <div className={'orderFinishTxt2'}>
            {`${ (order.store || {}).name}`}
          </div>
        </div>
      </div>

      <br/>
      
      <IonGrid className='OrderFinishMarTop orderFinishTxt '>
        <IonRow>
          <IonCol size={'6'} className='userCardCol txtGreen'>
            Identificación
          </IonCol>
          <IonCol size={'6'} className='userCardCol txtGreen ion-text-end'>
            {`${ (order.user || {}).identification}`}
          </IonCol>
          <IonCol size={'6'} className='userCardCol txtGreen'>
            Teléfono
          </IonCol>
          <IonCol size={'6'} className='userCardCol txtGreen ion-text-end'>
          {`${ (order.store || {}).phone}`}
          </IonCol>
          <IonCol size={'6'} className='userCardCol txtGreen '>
            Dirección
          </IonCol>
          <IonCol size={'6'} className='userCardCol txtGreen ion-text-end'>
            {`${ (order.store || {}).addres}`}
          </IonCol>
          <IonCol size={'6'} className='userCardCol txtGreen '>
            Correo Electrónico
          </IonCol>
          <IonCol size={'6'} className='userCardCol txtGreen ion-text-end'>
            {`${ (order.user || {}).email}`}
          </IonCol>
          
          <IonCol size={'4'} className='userCardCol txtGreen '>
            Fecha de creación
          </IonCol>
          <IonCol size={'8'} className='userCardCol txtGreen ion-text-end'>
          {`${ dayjs(order.createdAt).format('DD-MM-YYYY HH:mm:ss')}`} || {`${ dayjs(order.createdOrder).format('DD-MM-YYYY HH:mm:ss')}`}
          </IonCol>

        </IonRow>
      </IonGrid>

      <IonGrid>
        <IonRow className='orderDetailTxt1'>
          <IonCol size={'6'}>
            ORDEN
          </IonCol>
          <IonCol size={'6'} className='ion-text-end'>
            # {completeCodigo(''+order.codigo)}
          </IonCol>
        </IonRow>
        
        <IonRow className={'orderFinishTxt '}>
          <IonCol size={'6'}>
            Estatus
          </IonCol>
          <IonCol size={'6'} className='ion-text-end orderTxtRed' >
            {getStatus(''+order.idStatus)}
          </IonCol>
        </IonRow>

      </IonGrid>
      <IonGrid className='orderFinishTxt'>
        <IonRow>
          <IonCol size={'6'}>
            Sub-Total
          </IonCol>
          <IonCol size={'6'} className='ion-text-end orderTxtRed'>
            <NumberFormat
              prefix="$ "
              thousandSeparator="."
              decimalSeparator=","
              value={order.totalAmount}
              displayType={'text'}
            />
          </IonCol>
          
          <IonCol size={'6'}>
            Método de pago
          </IonCol>
          <IonCol size={'6'} className='ion-text-end orderTxtRed'>
          {`${ order.idPaymentMethod === TYPE.CONTRAENTREGA ? 'Contra entrega' : 'Consignación'}`}
          </IonCol>

          

          
          <IonCol size={'6'}>
            Nombre tienda
          </IonCol>
          <IonCol size={'6'} className='ion-text-end orderTxtRed'>
            {`${ (order.store || {}).name}`}
          </IonCol>
          
          <IonCol size={'6'}>
            Fecha 
          </IonCol>
          <IonCol size={'6'} className='ion-text-end orderTxtRed'>
            {`${ order.date ? order.date.format('DD-MM-YYYY')  : '' }`}
          </IonCol>

          <IonCol size={'6'}>
            Hora 
          </IonCol>
          <IonCol size={'6'} className='ion-text-end orderTxtRed'>
            {`${ order.time ? order.time.txt : '' }`}
          </IonCol>

        </IonRow>
        <IonRow>
          <IonCol size={'6'}>
            Descuento
          </IonCol>
          <IonCol size={'6'} className='ion-text-end orderTxtRed'>
            <NumberFormat
              prefix="$ "
              thousandSeparator="."
              decimalSeparator=","
              value={order.discountAmount}
              displayType={'text'}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size={'6'}>
            Retención
          </IonCol>
          <IonCol size={'6'} className='ion-text-end orderTxtRed'>
            <NumberFormat
              prefix="$ "
              thousandSeparator="."
              decimalSeparator=","
              value={order.retencion}
              displayType={'text'}
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size={'6'}>
            Total
          </IonCol>
          <IonCol size={'6'} className='ion-text-end orderTxtRed'>
            <NumberFormat
              prefix="$ "
              thousandSeparator="."
              decimalSeparator=","
              value={order.totalAmount - order.discountAmount}
              displayType={'text'}
            />
          </IonCol>
        </IonRow>

      </IonGrid>

      <IonGrid>
        { order.idPaymentMethod === TYPE.CONSIGNACION && 
          <IonRow className={'userCardUserValores userCardUserDocTxt ion-text-center'}>
          <IonCol size={'6'}>
            { rol.includes('5.4') &&
            <IonGrid onClick={() => clickRecibo()} className={ order.idStatus === TYPE.VALIDANDO ? 'UserCardDocBackRojo' : 'UserCardDocBack' }>
              <IonRow>
                  <IonCol size={'8'} >
                  Recibo
                </IonCol>
                <IonCol size={'4'}>
                  { order.support && 
                    <IonImg
                      className={'userCardUserDocImg'}
                      src={order.support}
                    />
                  }
                </IonCol>
              </IonRow>
            </IonGrid>
            } 
            </IonCol>
          </IonRow>
        }
      </IonGrid>
        
    </div>
  )
}