import { getAxio } from '../api';

const path = "product/"

export function putProduct(obj: any){
    return getAxio().put(path, obj).then((res:any) => {
        return res.data
     } ) 
}


export function putPolitica(obj: any){
    return getAxio().put(path + 'politica', obj).then((res:any) => {
        return res.data
     } ) 
}


export function getProductSearchTxt(search: string){
    return getAxio().get(path + 'all-txt/' + search).then((res:any) => {
        return res.data
     } ) 
}

