import { getAxio } from "../api";

const path = "notificacion/";

export function getAllNotifications() {
  return getAxio()
    .get(path + "all/-1")
    .then((res: any) => {
      return res.data;
    });
}

export function putNotification(value: any) {
  if (value.file) {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let fd = new FormData();
    fd.append("file", value.file ? value.file : {});
    return getAxio()
      .post(path + "uploads/", fd, config)
      .then((resImg: any) => {
        return getAxio()
          .put(path, {
            ...value,
            img: `https://sanapp.info/san-andres-api-dev/uploads/${resImg.data.message.filename}`,
          })
          .then((res: any) => {
            return res.data;
          });
      });
  } else {
    return getAxio()
      .put(path, { ...value })
      .then((res: any) => {
        return res.data;
      });
  }
}

export function createNotification(value: any) {
  console.info("valuevalue", value);
  if (value.file) {
    const config = { headers: { enctype: "multipart/form-data" } };
    let fd = new FormData();
    fd.append("file", value.file ? value.file : {});
    console.info("fd", fd);
    return getAxio()
      .post(path + "uploads/", fd, config)
      .then((resImg: any) => {
        console.info("resImg.data.filename", resImg);
        return getAxio()
          .post(path, { ...value, img: resImg.data.message.filename })
          .then((res: any) => {
            return res.data;
          });
      });
  } else {
    return getAxio()
      .post(path, { ...value })
      .then((res: any) => {
        return res.data;
      });
  }
}

export function deleteNotification(idDetail: string) {
  return getAxio()
    .delete(path + "detail/" + idDetail)
    .then((res: any) => {
      return res.data;
    });
}
