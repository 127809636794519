import React, { useState, useEffect } from "react";

import "./Header.css";
import { Link } from "react-router-dom";

import {
  TAB1,
  TAB11,
  TAB2,
  TAB22,
  TAB3,
  TAB33,
  TAB4,
  TAB44,
  TAB5,
  TAB55,
  TAB6,
  TAB66,
  TAB7,
  TAB77,
  LOGO,
  Salir,
} from "../../const/imgs";
import { IonGrid, IonRow, IonCol, IonImg, IonLoading } from "@ionic/react";
import { removeSesionData } from "../../api/user/user";

interface PropSegment {
  selected: string;
  modulo: string;
  props: any;
  rol: string;
}

export function Header({ selected, modulo, props, rol }: PropSegment) {
  const [showLoading, setShowLoading] = useState(false);

  const logOut = () => {
    setShowLoading(true);
    console.log("out");
    removeSesionData().then(() => {
      props.history.push("/login");
      setNewRol("0");
      setShowLoading(false);
    });
  };

  const [newRol, setNewRol] = useState("");

  useEffect(() => {
    setNewRol(rol);
    console.info("rolrolrol", rol);
  }, [rol]);

  return (
    <div className={"headerBack"}>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      <IonGrid className={"gridNoPadding"}>
        <IonRow>
          <IonCol size={"3"} className={"gridNoPadding"}>
            <IonGrid>
              <IonRow className="headerIconMariginTop">
                <IonCol size={"2"}>
                  {selected !== "1" && newRol.includes("1.0") && (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={{ pathname: "/user" }}
                    >
                      <IonImg className={"headerIcon"} src={TAB11} />
                    </Link>
                  )}
                  {selected === "1" && newRol.includes("1.0") && (
                    <IonImg className={"headerIcon"} src={TAB1} />
                  )}
                </IonCol>

                <IonCol size={"2"}>
                  {selected !== "2" && newRol.includes("2.0") && (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={{ pathname: "/product" }}
                    >
                      <IonImg className={"headerIcon"} src={TAB22} />
                    </Link>
                  )}
                  {selected === "2" && newRol.includes("2.0") && (
                    <IonImg className={"headerIcon"} src={TAB2} />
                  )}
                </IonCol>

                <IonCol size={"2"} className={""}>
                  {selected !== "3" && newRol.includes("3.0") && (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={{ pathname: "/promotion" }}
                    >
                      <IonImg className={"headerIcon"} src={TAB33} />
                    </Link>
                  )}
                  {selected === "3" && newRol.includes("3.0") && (
                    <IonImg className={"headerIcon"} src={TAB3} />
                  )}
                </IonCol>

                <IonCol size={"2"} className={""}>
                  {selected !== "4" && newRol.includes("4.0") && (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={{ pathname: "/zone" }}
                    >
                      <IonImg className={"headerIcon"} src={TAB44} />
                    </Link>
                  )}
                  {selected === "4" && newRol.includes("4.0") && (
                    <IonImg className={"headerIcon"} src={TAB4} />
                  )}
                </IonCol>

                <IonCol size={"2"} className={""}>
                  {selected !== "5" && newRol.includes("5.0") && (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={{ pathname: "/order" }}
                    >
                      <IonImg className={"headerIcon"} src={TAB55} />
                    </Link>
                  )}
                  {selected === "5" && newRol.includes("5.0") && (
                    <IonImg className={"headerIcon"} src={TAB5} />
                  )}
                </IonCol>

                <IonCol size={"2"} className={""}>
                  {selected !== "6" && newRol.includes("6.0") && (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={{ pathname: "/notification" }}
                    >
                      <IonImg className={"headerIcon"} src={TAB66} />
                    </Link>
                  )}
                  {selected === "6" && newRol.includes("6.0") && (
                    <IonImg className={"headerIcon"} src={TAB6} />
                  )}
                </IonCol>

                
              </IonRow>
            </IonGrid>
          </IonCol>
          <IonCol size={'2'} className="headerIconMariginTop2"  >
            {selected !== '7' &&
              <Link style={{ textDecoration: 'none' }} to={{pathname:'/logistica'}}>
                <IonImg
                  className={'headerIcon'}
                  src={TAB77}
                />
              </Link>
            }
            {selected === '7' &&
              <IonImg
                className={'headerIcon'}
                src={TAB7}
              />
            }
          </IonCol>
          <IonCol size={"3"} className={"gridNoPadding headerLineHeight7 "}>
            {newRol.includes("7.0") && (
              <Link
                style={{ textDecoration: "none" }}
                to={{ pathname: "/calendar" }}
              >
                <IonImg className={"headerLogo"} src={LOGO} />
              </Link>
            )}
            {!newRol.includes("7.0") && (
              <IonImg className={"headerLogo "} src={LOGO} />
            )}
          </IonCol>
          <IonCol
            size={"3"}
            className={
              "gridNoPadding headerLineHeight7 ion-text-end headerTitulo"
            }
          >
            {modulo}
          </IonCol>

          <IonCol
            size={"1"}
            className={
              "gridNoPadding headerLineHeight7 ion-text-center headerTitulo"
            }
            onClick={() => logOut()}
          >
            <img className={"logOut"} src={Salir} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
}
