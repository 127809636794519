import { IonGrid, IonRow, IonCol, IonImg } from "@ionic/react";
import React from "react";

import NumberFormat from "react-number-format";
import { CBB, ONLYCANASTA } from "../../const/imgs";


import './Product.css'

interface PropProducts{
    data: any[]
    setData: Function
    setShowModal: Function
    setObjProduct: Function
}

export function Products ( {
    data,
    setData,
    setShowModal,
    setObjProduct
}:PropProducts ){
    
return (
    <IonGrid>
        <IonRow>
            { data.map( (product: any, i: number) => {
                return (
                    <IonCol size={'2'} key={i}>
                        <IonGrid>
                            <IonRow>
                                <IonCol className='ion-text-center'>
                                    <NumberFormat
                                        prefix="$ "
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        value={product.presentations[product.iPresentation > -1 ? product.iPresentation : 0].price}
                                        displayType={'text'}
                                    />
                                </IonCol>
                            </IonRow>
        
                            <IonRow className='ion-text-center'>
                                <IonCol size={'12'} >
                                    <div
                                    className="productsCbbPresentation cbbOpracity cbbTop ion-text-center"
                                    onClick={() => {setObjProduct({...product}); setShowModal(true);}}
                                    >
                                        {product.presentations[product.iPresentation ? product.iPresentation : 0].detail}
                                        <IonImg
                                            className={'productsCbbIcon'}
                                            src={CBB}
                                        />
                                    </div>
                                    <IonImg
                                        className={'productsImg'}
                                        src={product.img}
                                        onClick={() => setObjProduct(product)}
                                    />
                                </IonCol>
                                <IonCol size={'12'} onClick={() => setObjProduct(product)}>
                                    <div className={'productsName'}>
                                        {product.name}
                                    </div>
                                    <div className={'productsContent'}>
                                        {product.content}
                                    </div>
                                </IonCol>
                                { (product.suggested === 1 || product.suggested === true) &&
                                    <IonCol>
                                        <IonImg
                                            src={ONLYCANASTA}
                                            className={'productsImgSuggested'}
                                        />
                                    </IonCol>
                                }
                            </IonRow>
        
                        </IonGrid>
        
                    </IonCol>
                )
            })
            }
      </IonRow>
    </IonGrid>
)
}