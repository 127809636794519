import { 
  IonContent,
  IonPage,
  IonLoading,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonItem,
  IonToggle,
} from '@ionic/react';

import React, { useState, useEffect } from 'react';

import './Cardinalidad.css'
import { Header } from '../../components/Header/Header';
import { Toast } from '../../components/Alert/Alert';

import { Input } from '../../components/Inputs/Input';
import { getAllCardinalidades, getAllCardinalidadesByDate, putCardinalidades } from '../../api/cardinalidad/cardinalidad';
import { getLocalUser } from '../../api/user/user';


const Cardinalidad: React.FC<any> = (props) => {
  
  const [rol, setRol] = useState('');

  const [showLoading, setShowLoading] = useState(true);
  const [cardinalidades, setCardinalidades] = useState<any>([]);
  const [dataOrder, setDataOrder] = useState<any>([]);
  
  const [toast, setToast] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const [fecha, setFecha] = useState('');


  useEffect(() => {
    const getData = async () => {
      try {
        setShowLoading(true)
        const {data} = await getAllCardinalidades();
        setCardinalidades(data)
        console.info("data")
        setShowLoading(false)
      } catch (error) {
        console.log("errrorr", error)
      }
    }
    getData()
    
    getLocalUser().then( (res2: any) => {
      setRol(""+res2.idRole)
    })
  }, [])

  const getCardinalidadesByDate = async () => {
    const { data } = await getAllCardinalidadesByDate(fecha)
    setDataOrder(data)
  }

  const setValue = (value: string, position: number, att: string) => {
    cardinalidades[position][att] = value
    setCardinalidades([...cardinalidades])
  }

  const saveCardinalidad = async (position: number) => {
    const a = await putCardinalidades(cardinalidades[position])
  }
  return (
    <IonPage>
      <IonContent className="">
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={'Please wait...'}
      />

      <Header
        selected={'7'}
        modulo={'Logística'}
        props={props}
        rol={rol}
      />

      {Toast( {
        message: mensaje,
        open: toast,
        onDissmis: () => { setToast(false)},
        showCloseBtn: true,
        duration: 3000,
        position:'bottom',
        }
      )}

        <IonGrid >
          <IonRow>
            {cardinalidades.map( (cardinalidad: any, index: number) => (
              <IonCol size={'6'} className={"containerCardinalidadForm"}>
                <IonGrid className={"containerCardinalidadForm2"}>
                  <IonRow>
                    <IonCol size={'6'}>
                      <Input
                          txt="Descripcion"
                          onInput={(value: string) => { setValue(value, index, "descripcion") }}
                          value={cardinalidad.descripcion}
                          positionTxt={"floating"}
                          type={'text'}
                      />
                    </IonCol>
                    <IonCol size={'6'}>
                      <Input
                          txt="Cantidad"
                          onInput={(value: string) => { setValue(value, index, "cantidad") }}
                          value={cardinalidad.cantidad}
                          positionTxt={"floating"}
                          type={'number'}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size={'2'} class="ion-text-center">
                      Lunes
                      <IonItem lines="none" className='productFormBack2 productFormSuggested2'>
                        <IonToggle  checked={cardinalidad.lunes} onIonChange={e => { cardinalidad.lunes = (!cardinalidad.lunes)}} />
                      </IonItem>
                    </IonCol>
                    <IonCol size={'2'} class="ion-text-center">
                      Martes
                      <IonItem lines="none" className='productFormBack2 productFormSuggested2'>
                        <IonToggle  checked={cardinalidad.martes} onIonChange={e => { cardinalidad.martes = (!cardinalidad.martes)}} />
                      </IonItem>
                    </IonCol>
                    <IonCol size={'2'} class="ion-text-center">
                      Mieroles
                      <IonItem lines="none" className='productFormBack2 productFormSuggested2'>
                        <IonToggle  checked={cardinalidad.miercoles} onIonChange={e => { cardinalidad.miercoles = (!cardinalidad.miercoles)}} />
                      </IonItem>
                    </IonCol>
                    <IonCol size={'2'} class="ion-text-center">
                      Jueves
                      <IonItem lines="none" className='productFormBack2 productFormSuggested2'>
                        <IonToggle  checked={cardinalidad.jueves} onIonChange={e => { cardinalidad.jueves = (!cardinalidad.jueves)}} />
                      </IonItem>
                    </IonCol>
                    <IonCol size={'2'} class="ion-text-center">
                      Viernes
                      <IonItem lines="none" className='productFormBack2 productFormSuggested2'>
                        <IonToggle  checked={cardinalidad.viernes} onIonChange={e => { cardinalidad.viernes = (!cardinalidad.viernes)}} />
                      </IonItem>
                    </IonCol>
                    <IonCol size={'2'} class="ion-text-center">
                      Sábado
                      <IonItem lines="none" className='productFormBack2 productFormSuggested2'>
                        <IonToggle  checked={cardinalidad.sabado} onIonChange={e => { cardinalidad.sabado = (!cardinalidad.sabado)}} />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonButton className='CardinalidadSave'  onClick={ () => saveCardinalidad(index)}>
                        Guardar
                    </IonButton>
                  </IonRow>
                </IonGrid>
              </IonCol>

            ))}    
          </IonRow>
        </IonGrid>

        <br/><br/>
        <IonGrid className={"containerCardinalidadForm2"}>
          <IonRow>
            <IonCol size={'6'}>
              <Input
                  txt="Fecha de busqueda"
                  onInput={(value: any) => { setFecha(value) }}
                  value={fecha}
                  positionTxt={"floating"}
                  type={'date'}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonButton className='CardinalidadSave'  onClick={ () => getCardinalidadesByDate()}>
                Buscar
            </IonButton>
          </IonRow>
        </IonGrid>
        
        <br/><br/>
        <IonGrid className={"containerCardinalidadList"}>
          {dataOrder.length > 0 &&
          <IonRow>
            <IonCol size={'1'}>
              Código
            </IonCol>
            <IonCol size={'2'}>
              Identificación
            </IonCol>
            <IonCol size={'4'}>
              Nombre
            </IonCol>
            <IonCol size={'2'}>
              Monto total
            </IonCol>
            <IonCol size={'2'}>
              Punto Cardinal
            </IonCol>
          </IonRow>}
          { dataOrder.map ( (order: any) => (
            <IonRow>
              <IonCol size={'1'}>
                {order.codigo}
              </IonCol>
              <IonCol size={'2'}>
                {order.user.identification}
              </IonCol>
              <IonCol size={'4'}>
                {order.user.name} {order.user.lastName}
              </IonCol>
              <IonCol size={'2'}>
                {order.totalAmount}
              </IonCol>
              <IonCol size={'2'}>
                {order?.cardinale?.descripcion}
              </IonCol>
            </IonRow>
          )) }
        </IonGrid>
      </IonContent>
    </IonPage>
  );
    
}


export default Cardinalidad;
