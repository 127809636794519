import { 
  IonContent,
  IonPage,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonLoading,
} from '@ionic/react';

import React, { useState, useEffect } from 'react';

import { logInAdmin } from '../../api/public/public'

import './Login.css'

import { 
  LOGO,
  EMAIL,
  LOCK,
  NEXT
} from '../../const/imgs'

import { Link } from 'react-router-dom';
import { Input } from '../../components/Inputs/Input';
import { Toast } from '../../components/Alert/Alert';
import { getLocal, getLocalUser } from '../../api/user/user';

const Login: React.FC<any> = (props) => {

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [showLoading, setShowLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const [token, setToken] = useState('');
//console.log(setToken)
  const login = () => {

    //console.log("corre", email, password)
    setShowLoading(true);
    
    const fetchData = async () => {
      try {
        const result = await logInAdmin({
          email,
          password,
          admin: true
        });
  
        setShowLoading(false);
        if(result.data !== -2){
          setEmail('')
          setPassword('')
            if(result.data)
              props.history.push("/user")
        }
        else{
          setMensaje('Error en la combinación de correo y contraseña')
          setToast(true)
        }
      } catch (error) {
        setMensaje('Ups! error en la petición')
        setToast(true)
      }
      
    };
  
    fetchData();

  }

  useEffect(() => {

    getLocal().then( (res) => {
      if(res){
        getLocalUser().then( (res2: any) => {
          if(res2){
            //props.history.push("/user")
          }
        })
      }
    })
  }, [token])
  

  const txt2 = 'Ingreso'
  
    return (
      <IonPage>
        <IonContent className="ion-padding ">
          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={'Please wait...'}
          />

          {Toast( {
            message: mensaje,
            open: toast,
            onDissmis: () => { setToast(false)},
            showCloseBtn: true,
            duration: 3000,
            position:'bottom',
            }
          )}
          <div className='marginaa'>
            <div className="registerContent">
              <div className="registerContent2">
                
                <IonGrid className=''>
                  <IonRow>
                    <IonCol className="ion-text-center icon">
                      <img className="loginImg" src={LOGO} alt=''/>
                    </IonCol>
                  </IonRow>
                </IonGrid>   

                <p className="ion-text-center iingreso fontmilkshake">
                  {txt2}
                </p>

                <Input
                  txt="Correo"
                  onInput={setEmail}
                  value={email}
                  positionTxt={"floating"}
                  type={'text'}
                  icon={EMAIL}
                />
<br/>
                <Input
                  txt="Contraseña"
                  onInput={setPassword}
                  value={password}
                  positionTxt={"floating"}
                  type={'password'}
                  icon={LOCK}
                />
                <br/>
              <div className="ion-text-center">
                <Link  style={{ fontSize: '16px', textDecoration: 'none', color: '#A6BCD0' }} to="/recuperar-clave">
                  Recuperar contraseña
                </Link>
              </div>  

              <IonButton className='loginMarginTop21 loginBtn'  onClick={ () => login()}>
                <IonImg className="icon02" src={NEXT} slot="start"/>
                Ingresar
              </IonButton>
              
              </div>
            </div>

            <div className="ion-text-center loginMarginTop21">
            <Link className="" style={{ fontSize: '14px', textDecoration: 'none', color: '#A6BCD0' }} to="/register">
              CREAR CUENTA
            </Link>
          </div>
          </div>
        
        </IonContent>
      </IonPage>
    );
    
  }


export default Login;
