import React, { useState, useEffect } from "react";

import "./UserCard.css";

import {
  TIENDAROJA,
  TIENDAVERDE,
  DOCADJ,
  SOLICIROJA,
  SOLICIVERDE,
  ORDENESROJA,
  ORDENESVERDE,
  DESABAJO,
  DESDERECHA,
  REFRESH,
  SAAVEE,
  ADJUNTO,
} from "../../const/imgs";

import { TAB1 } from "../../const/imgs";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonButton,
  IonTextarea,
  IonModal,
  IonAlert,
  IonItem,
  IonToggle,
} from "@ionic/react";
import { Input, InputSelect } from "../Inputs/Input";
import {
  spliceTxt,
  completeCodigo,
  getStatus,
  TYPE,
  getDataStatusUser,
} from "../../const/functions";
import NumberFormat from "react-number-format";

import { Map } from "../Map/Map";
import dayjs from "dayjs";
import { getComplete } from "../../api/pedido/pedido";
import { putOrderModification } from "../../api/orderModification/orderModification";
import { putUsuImg, putUsu, userClearDevice } from "../../api/user/user";

export interface PropCardUser {
  preSetSearchStatus: Function;
  searchStatus: string;
  rol: string;
  data: any;
  dataNotification: any;
  onClickUser: Function;
  setData?: Function;
  setShowLoading: Function;
  setAddres: Function;
  setCity: Function;
  setDepartment: Function;
  setLocality: Function;
  setNeighborhood: Function;
  setLatitude: Function;
  setLongitude: Function;
  updStore: Function;
  user: any;
  preSetSearch: Function;
  search: string;
  setMensaje: Function;
  setToast: Function;
  idStatusUser: string;
  setIdStatusUser: Function;
  refreshData: Function;
  setStorePhone: Function;
  storePhone: string;
  valueLength: any;
}

export interface PropModaDoc {
  obj: any;
  numDoc: number;
  render: boolean;
  setRender: Function;
  setShowLoading: Function;
  type?: number;
  support?: any;
  show?: boolean;
  onAceptar?: Function;
  onCancelar?: Function;
}

interface PropModalEditUsser {
  user: any;
  updUser: Function;
  refreshData2: Function;
}

export function ModalDocument({
  obj,
  numDoc,
  render,
  setRender,
  setShowLoading,
  type = 1,
  support = {},
  show = false,
  onAceptar,
  onCancelar,
}: PropModaDoc) {
  //console.log("supportsupportsupport", support)
  const downloadPDF = () => {
    if (type === 1) {
      if (obj.imgUser[numDoc]) {
        const string = obj.imgUser[numDoc].img;
        let name = "";
        switch (numDoc) {
          case 0:
            name = "cedula";
            break;
          case 1:
            name = "rut";
            break;
          case 2:
            name = "c. comercio";
            break;
        }
        const linkSource = `${string}`;
        const downloadLink = document.createElement("a");
        const fileName = `${name}.jpeg`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    }
    if (type === 2) {
      const linkSource = `${support.support}`;
      const downloadLink = document.createElement("a");
      const fileName = `Recibo_de_pago.jpeg`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  };

  const updDocument = async (status: number) => {
    try {
      setShowLoading(true);

      obj.imgUser[numDoc].status = status;
      let newDoc = {
        ...obj.imgUser[numDoc],
      };

      delete newDoc.img;

      await putUsuImg(newDoc);

      setRender(!render);

      setShowLoading(false);
    } catch (error) {
      console.log("errrorr", error);
    }
  };

  const getStatus = () => {
    if (obj.imgUser)
      if (obj.imgUser[numDoc])
        if (obj.imgUser[numDoc].status === 0) return true;

    return false;
  };

  const [aceptar, setAceptar] = useState(true);
  const [modalConfir, setModalConfir] = useState(false);

  return (
    <div>
      <IonAlert
        isOpen={modalConfir}
        onDidDismiss={() => {
          setModalConfir(false);
        }}
        header={"Confirmación"}
        message={` ¿ Desea guardar los cambios ? `}
        buttons={[
          {
            text: `No`,
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {},
          },
          {
            text: "Sí",
            handler: () => {
              if (aceptar) onAceptar ? onAceptar() : updDocument(2);

              if (!aceptar) onCancelar ? onCancelar() : updDocument(1);
            },
          },
        ]}
      />

      <IonGrid>
        <IonRow className="gridModal">
          <IonCol size={"3"}>
            <div className="modalDownload ">
              <IonGrid className={"UserCardNoPadding"}>
                <IonRow
                  className="ion-text-center UserCardNoPadding"
                  onClick={() => downloadPDF()}
                >
                  <IonCol
                    className="ion-text-center UserCardNoPadding"
                    size={"9"}
                  >
                    Descargar
                  </IonCol>
                  <IonCol
                    className="ion-text-left UserCardNoPadding"
                    size={"3"}
                  >
                    <IonImg
                      className="ModalIcon UserCardNoPadding"
                      src={TAB1}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  {type === 1 && (
                    <IonCol>
                      {obj
                        ? dayjs(obj.imgUser[numDoc].createdAt).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )
                        : ""}
                    </IonCol>
                  )}
                  {type === 2 && (
                    <IonCol>
                      {obj
                        ? dayjs(support.createdAt).format("DD-MM-YYYY HH:mm:ss")
                        : ""}
                    </IonCol>
                  )}
                </IonRow>
              </IonGrid>
            </div>
          </IonCol>
          {type === 1 && (
            <IonCol size={"6"}>
              <IonImg src={obj ? obj.imgUser[numDoc].img : ""} />
            </IonCol>
          )}

          {type === 2 && (
            <IonCol size={"6"}>
              <IonImg src={support.support} />
            </IonCol>
          )}

          {(getStatus() || show) && (
            <IonCol size={"3"}>
              <div className="modalDownload ">
                <IonGrid className={"UserCardNoPadding"}>
                  <IonRow
                    className="ion-text-center UserCardNoPadding"
                    onClick={() => {
                      setAceptar(true);
                      setModalConfir(true);
                    }}
                  >
                    <IonCol
                      className="ion-text-center UserCardNoPadding"
                      size={"9"}
                    >
                      Aceptar
                    </IonCol>
                    <IonCol
                      className="ion-text-left UserCardNoPadding"
                      size={"3"}
                    >
                      <IonImg
                        className="ModalIcon UserCardNoPadding"
                        src={TAB1}
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
              <br />
              <div className="modalDownload ">
                <IonGrid className={"UserCardNoPadding"}>
                  <IonRow
                    className="ion-text-center UserCardNoPadding"
                    onClick={() => {
                      setAceptar(false);
                      setModalConfir(true);
                    }}
                  >
                    <IonCol
                      className="ion-text-center UserCardNoPadding"
                      size={"9"}
                    >
                      Rechazar
                    </IonCol>
                    <IonCol
                      className="ion-text-left UserCardNoPadding"
                      size={"3"}
                    >
                      <IonImg
                        className="ModalIcon UserCardNoPadding"
                        src={TAB1}
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
              <br />
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </div>
  );
}

export function ModaleditUser({
  user = {},
  updUser,
  refreshData2,
}: PropModalEditUsser) {
  const [name, setName] = useState(user.name);
  const [lastName, setLastName] = useState(user.lastName);
  const [identification, setIdentification] = useState(user.identification);
  const [points, setPoints] = useState(user.points);
  const [minusPoints, setMinusPoints] = useState(0);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState(user.password);
  const [actividad, setActividad] = useState(user.actividad);
  const [responsabilidad, setResponsabilidad] = useState(user.responsabilidad);
  const [matricula, setMatricula] = useState(user.matricula);
  const [emailFacElec, setEmailFacElec] = useState(user.emailFacElec);
  const [facElec, setFacElec] = useState(user.facElec);
  const [codPostal, setCodPostal] = useState(user.codPostal);

  const upd = () => {
    const newUser = {
      id: user.id,
      name,
      lastName,
      identification,
      email,
      password,
      actividad,
      responsabilidad,
      matricula,
      emailFacElec,
      facElec,
      codPostal
    };
    updUser(newUser);
  };


  const updPoints = () => {
    const newUser = {
      id: user.id,
      points: user.points - minusPoints
    };
    console.info("newUser", newUser)
    updUser(newUser);
  };

  return (
    <div>
      <IonGrid>
        <IonRow className="modalEditUser">
          <IonCol size={"6"} className="userCardCol ion-text-right">
            <Input
              txt="Nombre"
              onInput={(w: string) => setName(w)}
              value={name}
              positionTxt={"floating"}
              type={"text"}
            />
          </IonCol>
          <IonCol size={"6"} className="userCardCol ion-text-right">
            <Input
              txt="Apellido"
              onInput={(w: string) => setLastName(w)}
              value={lastName}
              positionTxt={"floating"}
              type={"text"}
            />
          </IonCol>
          <IonCol size={"6"} className="userCardCol ion-text-right">
            <Input
              txt="Identificación"
              onInput={(w: string) => setIdentification(w)}
              value={identification}
              positionTxt={"floating"}
              type={"text"}
            />
          </IonCol>
          <IonCol size={"6"} className="userCardCol ion-text-right">
            <Input
              txt="Email"
              onInput={(w: string) => setEmail(w)}
              value={email}
              positionTxt={"floating"}
              type={"text"}
            />
          </IonCol>
          <IonCol size={"6"} className="userCardCol ion-text-right">
            <Input
              txt="Clave"
              onInput={(w: string) => setPassword(w)}
              value={password}
              positionTxt={"floating"}
              type={"text"}
            />
          </IonCol>
          <IonCol size={"6"} className="userCardCol ion-text-right">
            <Input
              txt="Actividad"
              onInput={(w: string) => setActividad(w)}
              value={actividad}
              positionTxt={"floating"}
              type={"text"}
            />
          </IonCol>
          <IonCol size={"6"} className="userCardCol ion-text-right">
            <Input
              txt="Responsabilidad tributaria"
              onInput={(w: string) => setResponsabilidad(w)}
              value={responsabilidad}
              positionTxt={"floating"}
              type={"text"}
            />
          </IonCol>
          <IonCol size={"6"} className="userCardCol ion-text-right">
            <Input
              txt="Matrícula mercantil"
              onInput={(w: string) => setMatricula(w)}
              value={matricula}
              positionTxt={"floating"}
              type={"text"}
            />
          </IonCol>
          <IonCol size={"6"} className="userCardCol ion-text-right">
            <Input
              txt="Email Factura Electrónica"
              onInput={(w: string) => setEmailFacElec(w)}
              value={emailFacElec}
              positionTxt={"floating"}
              type={"text"}
            />
          </IonCol>
          <IonCol size={"6"} className="userCardCol ion-text-right">
            <IonItem
              lines="none"
              className="productFormBack productFormSuggested"
            >
              Factura electrónica
              <IonToggle
                checked={facElec}
                onIonChange={(e) => {
                  setFacElec(!facElec);
                }}
              />
            </IonItem>
          </IonCol>
          <IonCol size={"6"} className="userCardCol ion-text-right">
            <Input
              txt="Código postal"
              onInput={(w: string) => setCodPostal(w)}
              value={codPostal}
              positionTxt={"floating"}
              type={"text"}
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size={"12"}>
            <IonButton className="" onClick={() => upd()}>
              Guardar
            </IonButton>
          </IonCol>
        </IonRow>
        
      </IonGrid>

      <IonGrid>
        <IonRow>
        <IonCol size={"6"} className="userCardCol ion-text-right">
            <Input
              txt="Redimir Puntos SanApp"
              onInput={(w: any) => setMinusPoints(w)}
              value={points}
              positionTxt={"floating"}
              type={"number"}
            />
          </IonCol>
        
        <IonCol size={"12"}>
            <IonButton className="" onClick={() => updPoints()}>
              Guardar
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
}

export function UserCards({
  data,
  onClickUser,
  setShowLoading,
  setAddres,
  setCity,
  setDepartment,
  setLocality,
  setNeighborhood,
  setLatitude,
  setLongitude,
  user,
  updStore,
  preSetSearch,
  search,
  dataNotification,
  setData,
  setMensaje,
  setToast,
  idStatusUser,
  setIdStatusUser,
  refreshData,
  rol,
  setStorePhone,
  storePhone,
  preSetSearchStatus,
  searchStatus,
  valueLength,
}: PropCardUser) {
  const [render, setRender] = useState(true);
  const [modal, setModal] = useState(false);
  const [opcSelected, setOpcSelected] = useState(0);

  const [objUser, setObjUser] = useState<any>({});
  const [indImg, setIndImg] = useState(0);

  const [modalSolicitud, setModalSolicitud] = useState(false);
  const [adjSolicitud, setAdjSolicitud] = useState<any>({});
  /*
  const [modalEditUser, setModalEditUser] = useState(false);

  const [actividad, setActividad] = useState('');
  const [responsabilidad, setResponsabilidad] = useState('');
  const [matricula, setMatricula] = useState('');
  const [emailFacElec, setEmailFacElec] = useState('');
  const [facElec, setFacElec] = useState(false);
  const [codPostal, setCodPostal] = useState('');
*/

  const [modalConDevice, setModalConDevice] = useState(false);

  const showAdjSolicitud = (adj: any) => {
    setModalSolicitud(true);
    setAdjSolicitud(adj);
  };

  const setAnswer = (value: string, obj: any) => {
    obj.answer = value;
  };

  const saveRespuesta = async (obj: any) => {
    try {
      setShowLoading(true);
      //console.log("obj", obj)
      if (!obj.answer) {
        setMensaje("Debe redactar una respuesta");
        setToast(true);
        setShowLoading(false);
        return;
      }
      const result = await putOrderModification(obj);
      const i = user.orderModification.findIndex(
        (oMod: any) => oMod.id === obj.id
      );
      user.orderModification[i] = result.data;
      setRender(!render);

      setShowLoading(false);
    } catch (error) {
      console.log("errrorr", error);
    }
  };

  const changeIdStatus = async () => {
    try {
      setShowLoading(true);
      const newUser = {
        ...user,
        idStatus: idStatusUser /*,
      actividad,
      responsabilidad,
      matricula,
      emailFacElec,
      facElec,
      codPostal*/,
      };
      await putUsu(newUser);
      const i = data.findIndex((item: any) => item.id === user.id);
      if (i > -1) {
        //console.log("iii", i)
        data[i] = { ...user, idStatus: idStatusUser };

        //console.log("newData", data)
        if (setData) {
          //console.log("setDatasetData")
          setData(data);
        }
      }
      setRender(!render);
      setShowLoading(false);
    } catch (error) {
      console.log("errrorr", error);
    }
  };

  const updUser = async (newUser: any) => {
    try {
      setShowLoading(true);
      await putUsu(newUser);
      await refreshData();
      setShowLoading(false);
    } catch (error) {
      console.log("errrorr", error);
    }
  };

  const setOpen = async (obj: any, i: number) => {
    //console.log("setOpen", opcSelected)
    setShowLoading(true);
    if (opcSelected === 1) {await fetchDetail(obj);}
    setRender(!render);
    setShowLoading(false);
  };

  const setOpenSup = (obj: any, i: number) => {
    setShowLoading(true);
    //console.log("setOpenSup", opcSelected)
    if (opcSelected === 2) obj.open = !obj.open;
    setRender(!render);
    setShowLoading(false);
  };

  const fetchDetail = async (obj: any) => {
    obj.open = !obj.open;
    if (obj.orderDetail) {
      return;
    }
    setShowLoading(true);
    try {
      const data = await getComplete(obj.id);
      obj.orderDetail = data.data;
      setShowLoading(false);
    } catch (error) {
      setShowLoading(false);
    }
  };

  const getDocBackground = (obj: any, i: number) => {
    //console.info('obj.imgUserobj.imgUserobj.imgUser', obj.imgUser)
    if (obj.imgUser)
      if (obj.imgUser[i]) {
        if (obj.imgUser[i].status === 1 || obj.imgUser[i].status === 0)
          return "UserCardDocBackRojo";
      }

    return "UserCardDocBack";
  };

  const getOpcTxt = () => {
    switch (opcSelected) {
      case 0:
        return "Tiendas";
      case 1:
        return "Ordenes";
      case 2:
        return "Solicitudes";
      case 3:
        return "Datos Usuarios";
    }
  };

  const showModal = (user: any, ind: number) => {
    //console.log("user", user)
    if (!user.imgUser) {
      setMensaje("El usuario no tiene documentos cargados");
      setToast(true);
      return;
    }
    if (!user.imgUser.length) {
      setMensaje("El usuario no tiene documentos cargados");
      setToast(true);
      return;
    }
    //console.log("user1111111111111111", user, ind)
    setObjUser(user);
    setIndImg(ind);
    setRender(!render);
    setModal(true);
  };

  return (
    <div className={""}>
      <IonAlert
        isOpen={modalConDevice}
        onDidDismiss={() => setModalConDevice(false)}
        header={"Confirmación"}
        message={` ¿ Desea eliminar el dispositivo del usuario ? `}
        buttons={[
          {
            text: `No`,
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {},
          },
          {
            text: "Sí",
            handler: () => {
              userClearDevice({ id: user.id }).then(() => {
                setMensaje("Se han eliminado los dispositivos del usuario");
                setToast(true);
              });
            },
          },
        ]}
      />

      <IonModal isOpen={modal} onDidDismiss={() => setModal(false)}>
        <ModalDocument
          numDoc={indImg}
          obj={objUser}
          render={render}
          setRender={setRender}
          setShowLoading={setShowLoading}
          show
        />
      </IonModal>
      <IonModal
        isOpen={modalSolicitud}
        onDidDismiss={() => setModalSolicitud(false)}
      >
        <ModalDocument
          numDoc={-1}
          obj={{}}
          render={true}
          setRender={() => {}}
          setShowLoading={setShowLoading}
          type={2}
          support={adjSolicitud}
        />
      </IonModal>
      <IonGrid>
        <IonRow>
          <IonCol size={"4"}>
            <IonGrid>
              <IonRow>
                <IonCol size={"6"}>
                  <Input
                    txt="Buscar"
                    onInput={(w: string) => preSetSearch(w)}
                    value={search}
                    positionTxt={"floating"}
                    type={"text"}
                  />
                </IonCol>
                <IonCol size={"5"}>
                  <InputSelect
                    txt="Filtro estado"
                    onInput={(idStatus: string) => {
                      preSetSearchStatus(idStatus);
                    }}
                    data={getDataStatusUser(false)}
                    positionTxt={"floating"}
                    value={searchStatus}
                    propId={"id"}
                    propTxt={"value"}
                    className=""
                  />
                </IonCol>
                <IonCol size={"1"} style={{ margin: "auto" }}>
                  <IonImg
                    onClick={() => {
                      refreshData();
                    }}
                    className={"btnRefresh"}
                    src={REFRESH}
                  />
                </IonCol>
              </IonRow>
              <IonRow key={-1} className="UserCardBackUser2">
                <IonCol size={"2"} className="userCardCol ion-text-justify">
                  Identificación
                </IonCol>
                <IonCol size={"3"} className="userCardCol ion-text-justify">
                  Nombre
                </IonCol>
                <IonCol size={"3"} className="userCardCol ion-text-justify">
                  Email
                </IonCol>
                <IonCol size={"2"} className="userCardCol ion-text-justify">
                  Teléfono
                </IonCol>
                <IonCol size={"2"} className="userCardCol ion-text-justify">
                  Tienda
                </IonCol>
              </IonRow>

              <div className="UserCardBackUser" style={{ overflowY: "scroll" }}>
                {data.map((obj: any, i: number) => {
                  return (
                    <IonRow
                      key={i}
                      onClick={() => {
                        onClickUser(obj, i);
                        setOpcSelected(0);
                      }}
                    >
                      <IonCol
                        size={"2"}
                        className="userCardCol border02 ion-text-justify txtGreen"
                      >
                        {obj.identification}
                      </IonCol>
                      <IonCol
                        size={"3"}
                        className="userCardCol border02 ion-text-justify txtGreen"
                      >
                        {obj.lastName} {obj.name},
                      </IonCol>
                      <IonCol
                        size={"3"}
                        className="userCardCol border02 ion-text-justify txtGreen"
                      >
                        {obj.email}
                      </IonCol>
                      <IonCol
                        size={"2"}
                        className="userCardCol border02 ion-text-justify txtGreen"
                      >
                        {obj.stores[0] ? obj.stores[0].phone : ""}
                      </IonCol>
                      <IonCol
                        size={"2"}
                        className="userCardCol border02 ion-text-justify txtGreen"
                      >
                        {obj.stores[0] ? obj.stores[0].name : ""}
                      </IonCol>
                    </IonRow>
                  );
                })}
              </div>

              <div className="UserCardBackNotification">
                <IonRow>
                  <IonCol
                    size={"3"}
                    className="userCardCol ion-text-justify UserCardNotificationTxt"
                  >
                    Notificaciones
                  </IonCol>
                  <IonCol
                    size={"1"}
                    className="userCardCol ion-text-center UserCardNotificationTxt2"
                  >
                    {dataNotification.length}
                  </IonCol>
                </IonRow>
                <div
                  className="UserCardBackNotificationDiv  "
                  style={{ overflowY: "scroll" }}
                >
                  {dataNotification.map((obj: any, i: number) => {
                    return (
                      <div key={(i + 1) * 10}>
                        <IonRow
                          key={i}
                          onClick={() => onClickUser(obj, i, true)}
                        >
                          {i === 0 && (
                            <IonCol size={"12"} ion-text-center>
                              Usuarios nuevos
                            </IonCol>
                          )}
                          {i === valueLength.new + 1 && (
                            <IonCol size={"12"}>Usuarios validando</IonCol>
                          )}
                          {
                          /*i === valueLength.new + valueLength.imgs && (
                            <IonCol size={"12"}>
                              Usuarios con solicitudes
                            </IonCol>
                          )*/
                          }
                          <IonCol
                            size={"2"}
                            className="userCardCol border02 ion-text-justify txtGreen"
                          >
                            {obj.identification}
                          </IonCol>
                          <IonCol
                            size={"3"}
                            className="userCardCol border02 ion-text-justify txtGreen"
                          >
                            {obj.lastName} {obj.name},
                          </IonCol>
                          <IonCol
                            size={"3"}
                            className="userCardCol border02 ion-text-justify txtGreen"
                          >
                            {obj.email}
                          </IonCol>
                          <IonCol
                            size={"2"}
                            className="userCardCol border02 ion-text-justify txtGreen"
                          >
                            {obj.stores[0] ? obj.stores[0].phone : ""}
                          </IonCol>
                          <IonCol
                            size={"2"}
                            className="userCardCol border02 ion-text-justify txtGreen"
                          >
                            {obj.stores[0] ? obj.stores[0].name : ""}
                          </IonCol>
                        </IonRow>
                      </div>
                    );
                  })}
                </div>
              </div>
            </IonGrid>
          </IonCol>

          <IonCol size={"4"}>
            <div className={"UserCardUserPresentation"}>
              <IonImg src={TIENDAROJA} className={"UserCardUserImg"} />
              <div className={"UserCardUserTxt"}>
                {user.lastName} {user.name} <br />
                {user.stores ? user.stores[0].name : ""}
              </div>
            </div>

            <div className="UserCarScrol2" style={{ overflowY: "scroll" }}>
              <IonGrid className={""}>
                <IonRow className={"userCardUserValores"}>
                  <IonCol
                    size={"6"}
                    className="userCardCol ion-text-justify bllack"
                  >
                    Identificación
                  </IonCol>
                  <IonCol size={"6"} className="userCardCol ion-text-right">
                    {user.identification}
                  </IonCol>

                  <IonCol
                    size={"6"}
                    className="userCardCol ion-text-justify bllack"
                  >
                    Teléfono
                  </IonCol>
                  <IonCol size={"6"} className="userCardCol ion-text-right">
                    {user.stores ? user.stores[0].phone : ""}
                  </IonCol>

                  <IonCol
                    size={"6"}
                    className="userCardCol ion-text-justify bllack"
                  >
                    Dirección
                  </IonCol>
                  <IonCol size={"6"} className="userCardCol ion-text-right">
                    {user.stores ? user.stores[0].addres : ""}
                  </IonCol>

                  <IonCol
                    size={"6"}
                    className="userCardCol ion-text-justify bllack"
                  >
                    Correo Electrónico
                  </IonCol>
                  <IonCol size={"6"} className="userCardCol ion-text-right">
                    {user.email}
                  </IonCol>

                  <IonCol
                    size={"6"}
                    className="userCardCol ion-text-justify bllack"
                  >
                    Puntos SanApp
                  </IonCol>
                  <IonCol size={"6"} className="userCardCol ion-text-right">
                    {user.points}
                  </IonCol>

                  <IonCol
                    size={"6"}
                    className="userCardCol ion-text-justify bllack"
                  >
                    Fecha de creación
                  </IonCol>
                  <IonCol size={"6"} className="userCardCol ion-text-right">
                    {dayjs(user.createdAt).format("DD-MM-YYYY")}
                  </IonCol>
                  {/*
                  <IonCol
                    size={'6'}
                    className='userCardCol ion-text-right'
                  >
                    <Input
                      txt="Actividad"
                      onInput={(w: string) => setActividad(w)}
                      value={actividad}
                      positionTxt={"floating"}
                      type={'text'}
                    />
                  </IonCol>
                  <IonCol
                    size={'6'}
                    className='userCardCol ion-text-right'
                  >
                    <Input
                      txt="Responsabilidad tributaria"
                      onInput={(w: string) => setResponsabilidad(w)}
                      value={responsabilidad}
                      positionTxt={"floating"}
                      type={'text'}
                    />
                  </IonCol>
                  <IonCol
                    size={'6'}
                    className='userCardCol ion-text-right'
                  >
                    <Input
                      txt="Matricula mercantil"
                      onInput={(w: string) => setMatricula(w)}
                      value={matricula}
                      positionTxt={"floating"}
                      type={'text'}
                    />
                  </IonCol>
                  <IonCol
                    size={'6'}
                    className='userCardCol ion-text-right'
                  >
                    <Input
                      txt="Email Fac. Electronica"
                      onInput={(w: string) => setEmailFacElec(w)}
                      value={emailFacElec}
                      positionTxt={"floating"}
                      type={'text'}
                    />
                  </IonCol>
                  <IonCol
                    size={'6'}
                    className=' ion-text-right'
                  >
                    <IonItem lines="none" className='productFormBack productFormSuggested'>
                        Fac. elec.
                        <IonToggle  checked={facElec} onIonChange={e => {setFacElec(!facElec)}} />
                    </IonItem>
                  </IonCol>
                  <IonCol
                    size={'6'}
                    className='userCardCol ion-text-right'
                  >
                    <Input
                      txt="Codigo Postal"
                      onInput={(w: string) => setCodPostal(w)}
                      value={codPostal}
                      positionTxt={"floating"}
                      type={'text'}
                    />
                  </IonCol>
*/}
                  <IonCol size={"6"} className="userCardCol ion-text-right">
                    <InputSelect
                      txt="Estado"
                      onInput={(idStatus: string) => {
                        setIdStatusUser(idStatus);
                      }}
                      data={getDataStatusUser()}
                      positionTxt={"floating"}
                      value={idStatusUser}
                      propId={"id"}
                      propTxt={"value"}
                      className=" "
                    />
                  </IonCol>
                  <IonCol
                    size={"1"}
                    className="ion-text-justify UserCardBtnSave"
                  >
                    {rol.includes("1.1") && (
                      <IonImg
                        src={SAAVEE}
                        className={"btnRefresh"}
                        onClick={() => changeIdStatus()}
                      />
                    )}
                  </IonCol>
                </IonRow>
                {rol.includes("1.3") && (
                  <IonRow>
                    <IonCol>
                      <IonButton
                        className=""
                        onClick={() => setModalConDevice(true)}
                      >
                        Limpiar dispositivo
                      </IonButton>
                    </IonCol>
                    <IonCol>
                      <IonButton className="" onClick={() => setOpcSelected(3)}>
                        Ver más datos
                      </IonButton>
                    </IonCol>                    
                  </IonRow>
                )}
                {rol.includes("1.2") && (
                  <div>
                    <br />
                    <div className={"UserCardTxtDoc"}>Documentos</div>
                    <IonRow
                      className={
                        "userCardUserValores userCardUserDocTxt ion-text-center"
                      }
                    >
                      <IonCol size={"4"}>
                        <IonGrid className={getDocBackground(user, 2)}>
                          <IonRow>
                            <IonCol
                              size={"8"}
                              onClick={() => {
                                showModal(user, 2);
                              }}
                            >
                              Cédula
                            </IonCol>
                            <IonCol size={"4"}>
                              <IonImg
                                className={"userCardUserDocImg"}
                                src={DOCADJ}
                              />
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCol>
                      <IonCol size={"4"}>
                        <IonGrid className={getDocBackground(user, 1)}>
                          <IonRow>
                            <IonCol
                              size={"8"}
                              onClick={() => {
                                showModal(user, 1);
                              }}
                            >
                              Rut
                            </IonCol>
                            <IonCol size={"4"}>
                              <IonImg
                                className={"userCardUserDocImg"}
                                src={DOCADJ}
                              />
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCol>
                      <IonCol size={"4"}>
                        <IonGrid className={getDocBackground(user, 0)}>
                          <IonRow>
                            <IonCol
                              size={"8"}
                              onClick={() => {
                                showModal(user, 0);
                              }}
                            >
                              C. comercio
                            </IonCol>
                            <IonCol size={"4"}>
                              <IonImg
                                className={"userCardUserDocImg"}
                                src={DOCADJ}
                              />
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCol>
                    </IonRow>
                  </div>
                )}
              </IonGrid>
              <br />
              <div className="UserCardTxtDoc">Top</div>
              <br />
              {(user.topProducts || []).length === 0 && (
                <div className="UserCardSinProductos ion-text-center">
                  El usuario aún no tiene productos registrados
                </div>
              )}
              <div className="UserCardPositionTotal">
                {(user.topProducts || []).length > 0 && (
                  <div className="ion-text-center">
                    <div className="UserCardTotal">Total Acumulado</div>
                    <div className="UserCardTotalValor">
                      <NumberFormat
                        prefix="$"
                        thousandSeparator="."
                        decimalSeparator=","
                        value={user.totalSale ? user.totalSale[0].count : 0}
                        decimalScale={2}
                        displayType={"text"}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div>
                {(user.topProducts || []).map((top: any, ind: any) => (
                  <IonGrid className="UserCardNoPadding" key={ind}>
                    <IonRow className="">
                      <IonCol size={"6"}>
                        <IonGrid className={"UserCardNoPadding"}>
                          <IonRow className="CardUserProductsBack">
                            <IonCol size={"3"}>
                              <IonImg
                                className={"userCardImgProduct"}
                                src={top.product.img}
                              />
                            </IonCol>
                            <IonCol size={"6"}>
                              <div className={"UserCardTxtProduct "}>
                                {top.product.name}
                              </div>
                              <div className={"UserCardTxtProduct"}>
                                {top.product.content}
                              </div>
                            </IonCol>
                            <IonCol
                              size={"3"}
                              className={"UserCardCantProduct "}
                            >
                              {top.quantity}
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                ))}
                <div className="floatLeft ion-text-center"></div>
                <br />
                <div
                  className={
                    opcSelected === 2
                      ? "UserCardUserOpc rosa"
                      : "UserCardUserOpc"
                  }
                >
                  <IonImg
                    src={opcSelected === 2 ? SOLICIROJA : SOLICIVERDE}
                    className={"UserCardUserImg"}
                  />
                  <div
                    className={
                      opcSelected === 2
                        ? "UserCardUserTxtBig1 UserCardUserTxtBigRojo ion-text-center"
                        : "UserCardUserTxtBig1 ion-text-center"
                    }
                    onClick={() => setOpcSelected(2)}
                  >
                    Solicitudes
                  </div>
                  <br />
                  <br />
                </div>
                <br />
                <div
                  className={
                    opcSelected === 0
                      ? "UserCardUserOpc rosa"
                      : "UserCardUserOpc"
                  }
                >
                  <IonImg
                    src={opcSelected === 0 ? TIENDAROJA : TIENDAVERDE}
                    className={"UserCardUserImg"}
                  />
                  <div
                    className={
                      opcSelected === 0
                        ? "UserCardUserTxtBig1 UserCardUserTxtBigRojo ion-text-center"
                        : "UserCardUserTxtBig1 ion-text-center"
                    }
                    onClick={() => setOpcSelected(0)}
                  >
                    Tiendas
                  </div>
                </div>
                <br />
                <div
                  className={
                    opcSelected === 1
                      ? "UserCardUserOpc rosa"
                      : "UserCardUserOpc"
                  }
                >
                  <IonImg
                    src={opcSelected === 1 ? ORDENESROJA : ORDENESVERDE}
                    className={"UserCardUserImg"}
                  />
                  <div
                    className={
                      opcSelected === 1
                        ? "UserCardUserTxtBig1 UserCardUserTxtBigRojo ion-text-center"
                        : "UserCardUserTxtBig1 ion-text-center"
                    }
                    onClick={() => setOpcSelected(1)}
                  >
                    Ordenes
                  </div>
                </div>
              </div>
            </div>
          </IonCol>
          <IonCol size={"4"}>
            <div className={"UserCardtxtTiendas"}>{getOpcTxt()}</div>
            <br />
            <br />
            <br />
            <IonGrid className="UserCarScrol3" style={{ overflowY: "scroll" }}>
              {opcSelected === 0 &&
                (user.stores || []).map((store: any, iStore: number) => {
                  return (
                    <div key={iStore} className="UserCardContainerStore">
                      <IonRow>
                        <IonCol
                          size={"12"}
                          className="userCardCol ion-text-justify"
                        >
                          <div className={"UserCardTiendaName"}>
                            {store.name}
                          </div>
                          <br />
                          <Input
                            txt="Teléfono"
                            onInput={(w: string) => setStorePhone(w)}
                            value={store.phone}
                            positionTxt={"floating"}
                            type={"text"}
                          />
                          <br />
                          <div className={"minMap"}>
                            <Map
                              storeId={store.id}
                              loading={setShowLoading}
                              setAddres={setAddres}
                              setCity={setCity}
                              setDepartment={setDepartment}
                              setLocality={setLocality}
                              setNeighborhood={setNeighborhood}
                              setLatitude={setLatitude}
                              setLongitude={setLongitude}
                              initLoc={{
                                lat: Number(store.latitude),
                                lng: store.longitude,
                                addres: store.addres
                              }}
                              /*
                              defLocation={{
                                latitude: Number( store.latitude),
                                longitude: Number( store.longitude)
                              }}
                              upd={true}
                              */
                            />
                          </div>
                        </IonCol>
                      </IonRow>
                      <IonRow className={"UserCardTiendaDatos"}>
                        <IonCol size={"2"} className="bllack">
                          Dirección:
                        </IonCol>
                        <IonCol size={"4"}>{store.addres}</IonCol>

                        <IonCol size={"2"} className="bllack">
                          Teléfono:
                        </IonCol>
                        <IonCol size={"4"}>{store.phone}</IonCol>

                        <IonCol size={"2"} className="bllack">
                          Localidad:
                        </IonCol>
                        <IonCol size={"4"}>{store.locality}</IonCol>

                        <IonCol size={"2"} className="bllack">
                          Ciudad:
                        </IonCol>
                        <IonCol size={"4"}>{store.city}</IonCol>

                        <IonCol size={"2"} className="bllack">
                          Barrio:
                        </IonCol>
                        <IonCol size={"4"}>{store.neighborhood}</IonCol>

                        <IonCol size={"2"} className="bllack">
                          Dpto:
                        </IonCol>
                        <IonCol size={"4"}>{store.department}</IonCol>
                      </IonRow>
                      {rol.includes("1.3") && (
                        <IonRow>
                          <IonButton
                            className="saveMap"
                            onClick={() => updStore(store.id)}
                          >
                            Guardar
                          </IonButton>
                        </IonRow>
                      )}
                    </div>
                  );
                })}

              {opcSelected === 1 && (
                <div>
                  {(user.orderDetail || []).length > 0 &&
                    user.orderDetail.map((order: any, ind: number) => (
                      <div className="orderBox " key={ind}>
                        <IonGrid>
                          <IonRow
                            className="orderRow lineHe"
                            onClick={() => setOpen(order, ind)}
                          >
                            <IonCol size={"11"}>
                              Orden #{completeCodigo("" + order.codigo)}
                            </IonCol>
                            <IonCol size={"1"}>
                              <IonImg
                                className={"IconDesAbajo"}
                                src={order.open ? DESABAJO : DESDERECHA}
                              />
                            </IonCol>
                          </IonRow>

                          {order.open && (
                            <div className="orderMargin8">
                              {(order.orderDetail || []).map(
                                (item: any, i: number) => (
                                  <IonRow
                                    className="orderRow"
                                    key={(ind + 1 * 10) * (i + 100)}
                                  >
                                    <IonCol
                                      size={"11"}
                                      className="orderRowProduct"
                                    >
                                      {(item.idProduct
                                        ? item.product.name
                                        : item.promotion.name) +
                                        " " +
                                        (item.idProduct
                                          ? item.presentation.detail
                                          : "")}
                                    </IonCol>
                                    <IonCol
                                      size={"1"}
                                      className="orderRowProduct ion-text-right"
                                    >
                                      {item.quantity}
                                    </IonCol>
                                  </IonRow>
                                )
                              )}
                            </div>
                          )}
                          {order.open && (
                            <div className="orderMargin8">
                              <IonRow className="orderRow">
                                <IonCol size={"2"} className="orderRowProduct">
                                  Fecha
                                </IonCol>
                                <IonCol
                                  size={"10"}
                                  className="orderRowProduct ion-text-right"
                                >
                                  {order.promisedDeliveryDate}
                                </IonCol>
                              </IonRow>
                            </div>
                          )}
                          {order.open && order.discountAmount > 0 && (
                            <div className="orderMargin8">
                              <IonRow className="orderRow colorRed">
                                <IonCol size={"5"} className="orderRowProduct ">
                                  Ahorro
                                </IonCol>
                                <IonCol
                                  size={"7"}
                                  className="orderRowProduct ion-text-right"
                                >
                                  <NumberFormat
                                    prefix=" $ "
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    value={order.discountAmount}
                                    displayType={"text"}
                                  />
                                </IonCol>
                              </IonRow>
                            </div>
                          )}
                          {order.open && (
                            <div className="orderMargin8">
                              <IonRow className="orderRow">
                                <IonCol
                                  size={"5"}
                                  className="orderRowProduct"
                                  style={{
                                    color:
                                      order.idStatus === TYPE.ALISTADO ||
                                      order.idStatus === TYPE.ENTREGADO
                                        ? "#7BED8D"
                                        : "#E66E5A",
                                  }}
                                >
                                  {spliceTxt(
                                    "" + getStatus(order.idStatus),
                                    15
                                  )}
                                </IonCol>
                                <IonCol
                                  size={"7"}
                                  className="orderRowProduct ion-text-right"
                                >
                                  Total:
                                  <NumberFormat
                                    prefix=" $ "
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    value={
                                      order.totalAmount - order.discountAmount
                                    }
                                    displayType={"text"}
                                  />
                                </IonCol>
                              </IonRow>
                            </div>
                          )}
                        </IonGrid>
                      </div>
                    ))}
                  {(user.orderDetail || []).length === 0 && (
                    <div className="UserCardSinProductos ion-text-center">
                      El usuario aún no tiene ordenes realizadas
                    </div>
                  )}
                </div>
              )}

              {opcSelected === 2 && (
                <div>
                  {(user.orderModification || []).length > 0 &&
                    user.orderModification.map((element: any, ind: number) => (
                      <div className="" key={ind}>
                        <IonGrid>
                          <div
                            className={
                              element.idStatus === TYPE.RESUELTO
                                ? "orderBox"
                                : "orderBox"
                            }
                          >
                            <IonRow
                              className={
                                element.idStatus === TYPE.RESUELTO
                                  ? "orderRow lineHe"
                                  : "orderRow lineHe rosa"
                              }
                              onClick={() => setOpenSup(element, ind)}
                            >
                              <IonCol size={"11"}>
                                Solicitud #{completeCodigo("" + element.codigo)}
                              </IonCol>
                              <IonCol size={"1"}>
                                <IonImg
                                  className={"IconDesAbajo"}
                                  src={element.open ? DESABAJO : DESDERECHA}
                                />
                              </IonCol>
                            </IonRow>
                            {element.open && (
                              <div className="OrderMargin8">
                                <div
                                  className={
                                    element.idStatus === TYPE.RESUELTO
                                      ? ""
                                      : " rosa"
                                  }
                                >
                                  <IonRow>
                                    <IonCol
                                      size={"4"}
                                      className="solicitudesTxt"
                                    >
                                      Fecha
                                    </IonCol>
                                    <IonCol
                                      size={"8"}
                                      className="solicitudesTxt ion-text-end"
                                    >
                                      {dayjs(element.createdAt).format(
                                        "DD-MM-YYYY HH:mm"
                                      )}
                                    </IonCol>
                                  </IonRow>

                                  <IonRow>
                                    <IonCol
                                      size={"4"}
                                      className="solicitudesTxt"
                                    >
                                      Tipo
                                    </IonCol>
                                    <IonCol
                                      size={"8"}
                                      className="solicitudesTxt ion-text-end"
                                    >
                                      {getStatus(element.idType)}
                                    </IonCol>
                                  </IonRow>

                                  <IonRow>
                                    <IonCol size={"12"} className="">
                                      Comentario
                                    </IonCol>
                                    <IonCol size={"12"} className="">
                                      {element.commentary}
                                    </IonCol>
                                  </IonRow>

                                  <br />

                                  <IonRow>
                                    <IonCol size={"12"} className="">
                                      Respuesta
                                    </IonCol>
                                    <IonCol size={"12"} className="">
                                      {element.idStatus !== TYPE.RESUELTO && (
                                        <IonTextarea
                                          placeholder={"Respuesta"}
                                          value={element.answer}
                                          onIonChange={(e: any) =>
                                            setAnswer(
                                              "" + e.detail.value,
                                              element
                                            )
                                          }
                                          className="orderRow"
                                          rows={5}
                                        />
                                      )}
                                      {element.idStatus === TYPE.RESUELTO && (
                                        <div className="">{element.answer}</div>
                                      )}
                                    </IonCol>
                                  </IonRow>

                                  {element.support && (
                                    <IonRow>
                                      <IonCol size={"11"}></IonCol>
                                      <IonCol
                                        size={"1"}
                                        onClick={() =>
                                          showAdjSolicitud({
                                            support: element.support,
                                          })
                                        }
                                      >
                                        <IonImg src={ADJUNTO} />
                                      </IonCol>
                                    </IonRow>
                                  )}

                                  <IonRow>
                                    <IonCol
                                      size={"12"}
                                      style={{
                                        color:
                                          element.idStatus === TYPE.CREADO
                                            ? "#E66E5A"
                                            : "#7BED8D",
                                      }}
                                    >
                                      {element.idStatus === TYPE.CREADO
                                        ? "Pendiente"
                                        : "Resuelto"}
                                    </IonCol>
                                  </IonRow>

                                  {element.idStatus !== TYPE.RESUELTO &&
                                    rol.includes("1.4") && (
                                      <IonRow>
                                        <IonButton
                                          className="saveMap"
                                          onClick={() =>
                                            saveRespuesta({
                                              ...element,
                                              idStatus: TYPE.RESUELTO,
                                            })
                                          }
                                        >
                                          Guardar
                                        </IonButton>
                                      </IonRow>
                                    )}
                                </div>

                                <br />
                              </div>
                            )}
                          </div>
                        </IonGrid>
                      </div>
                    ))}
                  {(user.orderModification || []).length === 0 && (
                    <div className="UserCardSinProductos ion-text-center">
                      El usuario aún no tiene solicitudes
                    </div>
                  )}
                </div>
              )}

              {opcSelected === 3 && (
                <div>
                  <ModaleditUser
                    user={user}
                    updUser={updUser}
                    refreshData2={refreshData}
                  />
                </div>
              )}
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
}
