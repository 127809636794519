import React from "react";
import "./Calendar.css";
import dayjs from "dayjs";
import moment from "moment";
var { Calendar, CalendarControls } = require("react-yearly-calendar");

interface PropCalendars {
  year: any;
  hollidays: string[];
  changeYear: Function;
  setDayOfCalendar: Function;
  selectedDay: any;
}

export function Calendars({
  year,
  changeYear,
  hollidays,
  setDayOfCalendar,
  selectedDay,
}: PropCalendars) {
  const customClasses = {
    holidays: hollidays,
  };

  return (
    <div>
      <CalendarControls
        year={year}
        onPrevYear={() => changeYear(false)}
        onNextYear={() => changeYear(true)}
      />
      <Calendar
        year={year}
        selectedDay={selectedDay}
        onPickDate={(day: any) => setDayOfCalendar(dayjs(day))}
        customClasses={customClasses}
      />
    </div>
  );
}
