import { getAxio } from '../api';

const path = "order-modification/"


export function insertModification(data:any){
    return getAxio().post(path, data).then((res:any) => {
        return res.data
    })
}


export function putModification(data:any){
    return getAxio().put(path, data).then((res:any) => {
        return res.data
    })
}

export function putModificationClean(data:any){
    return getAxio().put('order/clean', data).then((res:any) => {
        return res.data
    })
}

export function getAllOrderModification(idUser: string){
    return getAxio().get(path + 'all/' + idUser).then((res:any) => {
        return res.data
    })
}

export function getWebUserOrderModification(idUser: string){
    return getAxio().get(path + 'user/web/all/' + idUser).then((res:any) => {
        return res.data
    })
}

export function putOrderModification(data: any){
    return getAxio().put(path , data).then((res:any) => {
        return res.data
    })
}