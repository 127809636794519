import { IonGrid, IonRow, IonCol, IonButton } from "@ionic/react";
import React from "react";
import { InputSelect, Input } from "../../components/Inputs/Input";

interface PropFilter{
    setShowLoading: Function
    setCategory: Function
    setSubCategory: Function
    valueCategory: string
    valueSubCategory: string
    dataCategory: any[]
    dataSubCategory: any[]
    dataStatus: any[]
    setStatus: Function,
    status: string,
    setSearch: Function,
    search: string
    changePolitica: Function,
    rol: string
  }

export function ProductFilter ( {
    setCategory,
    dataCategory,
    valueCategory,
    setSubCategory,
    dataSubCategory,
    valueSubCategory,
    setSearch,
    search,
    changePolitica,
    dataStatus,
    setStatus,
    status,
    rol
}:PropFilter ){
    
  return (
    <IonGrid>
        <IonRow>
            <IonCol>
                <InputSelect
                    txt="Categoria"
                    onInput={(w:string) => setCategory(w)}
                    data={dataCategory}
                    positionTxt={"floating"}
                    value={valueCategory}
                    propId={'id'}
                    propTxt={'name'}
                />
            </IonCol>
            <IonCol>
                <InputSelect
                    txt="Sub categoria"
                    onInput={(v: string) => setSubCategory(v)}
                    data={dataSubCategory}
                    positionTxt={"floating"}
                    value={valueSubCategory}
                    propId={'id'}
                    propTxt={'name'}
                />
            </IonCol>
            <IonCol>
                <InputSelect
                    txt="Estado"
                    onInput={(v: string) => setStatus(v)}
                    data={dataStatus}
                    positionTxt={"floating"}
                    value={status}
                    propId={'id'}
                    propTxt={'name'}
                />
            </IonCol>
                
            <IonCol>
                <Input
                    txt="Buscar"
                    onInput={(v: string) => setSearch(v)}
                    value={search}
                    positionTxt={"floating"}
                    type={'text'}
                />
            </IonCol>
            { rol.includes('2.1') &&
                <IonCol>
                    <IonButton className='ProductFilterPolitica'  onClick={ () => changePolitica()}>
                        Política
                    </IonButton>
                </IonCol>
            }
        </IonRow>
    </IonGrid>
  )
  }