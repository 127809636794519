import { getAxio } from "../api";

const path = "calendar/";

export function postCalendar(value: any) {
  return getAxio()
    .post(path, value)
    .then((res: any) => {
      return res.data;
    });
}

export function getCalendar(allDay: boolean) {
  return getAxio()
    .get(path + "all-day/" + allDay)
    .then((res: any) => {
      return res.data;
    });
}

export function getDaysOfYear(year: string) {
  return getAxio()
    .get(path + "day-of-year/" + year)
    .then((res: any) => {
      return res.data;
    });
}

export function putCalendar(value: any) {
  return getAxio()
    .put(path, value)
    .then((res: any) => {
      return res.data;
    });
}

export function deleteCalendar(idCalendar: string) {
  return getAxio()
    .delete(path + idCalendar)
    .then((res: any) => {
      return res.data;
    });
}
