import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonToggle,
  IonButton,
  IonImg,
} from "@ionic/react";
import React from "react";

import "./ProductForm.css";
import { Input, InputSelect } from "../../components/Inputs/Input";
import dayjs from "dayjs";
import { PESOS } from "../../const/imgs";

interface PropProductForm {
  product: any;
  saveProduct: Function;
  rol: string;
}

export function ProductForm({
  product = {},
  saveProduct,
  rol,
}: PropProductForm) {
    let valUsePrice = '1'
    let valIndexPresentation = 0
  const handleOnInput = (product: any, value: any) => {
    if (product) {
      if (product.presentations) {
        product.presentations[
          product?.iPresentation ? product?.iPresentation : 0
        ].usePrice = value;
        valUsePrice = value
        valIndexPresentation = product?.iPresentation ? product?.iPresentation : 0
      }
    }
    
  };

  const handleValue = (product: any) => {
    return product.presentations
      ? product.presentations[product.iPresentation ? product.iPresentation : 0]
          .usePrice === 1
        ? "1"
        : "2"
      : "1";
  };
  

  return product ? (
    <IonGrid>
      <IonRow>
        <IonCol size={"6"}>
          <div className={"productFormName "}>
            {product.name ? product.name : "Nombre"}
          </div>
        </IonCol>
        <IonCol size={"6"}>
          <IonImg className={"productFormImg"} src={product.img} />
        </IonCol>
        <IonCol size={"6"}>
          <Input
            txt="SKU"
            onInput={() => {}}
            value={
              product.presentations
                ? product.presentations[
                    product.iPresentation ? product.iPresentation : 0
                  ].sku
                : ""
            }
            positionTxt={"floating"}
            type={"text"}
            disabled
          />
        </IonCol>
        <IonCol size={"6"}>
          <Input
            txt="Contenido"
            onInput={(value: string) => {
              product.content = value;
            }}
            value={product.content}
            positionTxt={"floating"}
            type={"text"}
          />
        </IonCol>
        <IonCol size={"6"}>
          <Input
            txt="Presentación"
            onInput={(value: string) => {
              product.presentations[
                product.iPresentation ? product.iPresentation : 0
              ].detail = value;
            }}
            value={
              product.presentations
                ? product.presentations[
                    product.iPresentation ? product.iPresentation : 0
                  ].detail
                : ""
            }
            positionTxt={"floating"}
            type={"text"}
          />
        </IonCol>
        <IonCol size={"6"}>
          <Input
            txt="Precio"
            onInput={(value: string) => {
              product.presentations[
                product.iPresentation ? product.iPresentation : 0
              ].price = value;
            }}
            value={
              product.presentations
                ? product.presentations[
                    product.iPresentation ? product.iPresentation : 0
                  ].price
                : ""
            }
            positionTxt={"floating"}
            type={"text"}
            icon={PESOS}
            disabled
          />
        </IonCol>
        <IonCol size={"6"}>
          <IonItem
            lines="none"
            className="productFormBack productFormSuggested"
          >
            Sugerido
            <IonToggle
              checked={product.suggested}
              onIonChange={(e) => {
                product.suggested = !product.suggested;
              }}
            />
          </IonItem>
        </IonCol>
        <IonCol size={"6"}>
          <IonItem
            lines="none"
            className="productFormBack productFormSuggested"
          >
            Recomendado
            <IonToggle
              checked={product.recomendado}
              onIonChange={(e) => {
                product.recomendado = !product.recomendado;
              }}
            />
          </IonItem>
        </IonCol>

        <IonCol size={"6"}>
          <IonItem
            lines="none"
            className="productFormBack productFormSuggested"
          >
            Show Banner
            <IonToggle
              checked={product.showBanner}
              onIonChange={(e) => {
                product.showBanner = !product.showBanner;
              }}
            />
          </IonItem>
        </IonCol>

        <IonCol size={"6"}>
          <Input
            txt="Puntos SanApp"
            onInput={(value: string) => {
              product.presentations[
                product.iPresentation ? product.iPresentation : 0
              ].points = value;
            }}
            value={
              product.presentations
                ? product.presentations[
                    product.iPresentation ? product.iPresentation : 0
                  ].points
                : ""
            }
            positionTxt={"floating"}
            type={"text"}
            disabled
          />
        </IonCol>

        <IonCol size={"6"}>
          <Input
            txt="Precio 01"
            onInput={(value: string) => {
              product.presentations[
                product.iPresentation ? product.iPresentation : 0
              ].price = value;
            }}
            value={
              product.presentations
                ? product.presentations[
                    product.iPresentation ? product.iPresentation : 0
                  ].price1
                : ""
            }
            positionTxt={"floating"}
            type={"text"}
            icon={PESOS}
            disabled
          />
        </IonCol>
        <IonCol size={"6"}>
          <Input
            txt="Precio 02"
            onInput={(value: string) => {
              product.presentations[
                product.iPresentation ? product.iPresentation : 0
              ].price = value;
            }}
            value={
              product.presentations
                ? product.presentations[
                    product.iPresentation ? product.iPresentation : 0
                  ].price2
                : ""
            }
            positionTxt={"floating"}
            type={"text"}
            icon={PESOS}
            disabled
          />
        </IonCol>
        
        <IonCol size={"6"}>
          <InputSelect
            txt="Precio a utilizar"
            onInput={(value: any) => {
              handleOnInput(product, value);
            }}
            data={[
              { id: "1", name: "Precio 01" },
              { id: "2", name: "Precio 02" },
            ]}
            positionTxt={"floating"}
            value={handleValue(product)}
            propId={"id"}
            propTxt={"name"}
            className=""
          />
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol size={"12"}>
          <div className={"productFormPoli"}>Políticas</div>
        </IonCol>

        <IonCol size={"5"}>
          <div className={"productFormBackOther"}>cantidad</div>
        </IonCol>

        <IonCol size={"7"}>
          <Input
            txt=" "
            onInput={(value: string) => {
              product.quantity = value;
            }}
            value={
              product.presentations?.length > -1
                ? product.presentations[
                    product.iPresentation > -1 ? product.iPresentation : 0
                  ].existence
                : "0"
            }
            positionTxt={"floating"}
            type={"number"}
            disabled
          />
        </IonCol>

        <IonCol size={"4"}>
          <div className={"productFormBackDate"}>Vigencia</div>
        </IonCol>

        <IonCol size={"4"}>
          <Input
            txt="Desde"
            onInput={(value: string) => {
              product.start = value;
            }}
            value={"" + dayjs(product.start).format("YYYY-MM-DD")}
            positionTxt={"floating"}
            type={"date"}
          />
        </IonCol>

        <IonCol size={"4"}>
          <Input
            txt="Hasta"
            onInput={(value: string) => {
              product.end = value;
            }}
            value={"" + dayjs(product.end).format("YYYY-MM-DD")}
            positionTxt={"floating"}
            type={"date"}
          />
        </IonCol>

        <IonCol size={"5"}>
          <div className={"productFormBackOther"}>Unidades por pedido</div>
        </IonCol>
        <IonCol size={"7"}>
          <Input
            txt=" "
            onInput={(value: string) => {
              product.orderQuantity = value;
            }}
            value={product.orderQuantity}
            positionTxt={"floating"}
            type={"number"}
          />
        </IonCol>
      </IonRow>
      {rol.includes("2.2") && (
        <IonRow>
          <IonButton
            className="productFormSave"
            onClick={() => {saveProduct(product, valIndexPresentation, valUsePrice)}}
          >
            Guardar
          </IonButton>
        </IonRow>
      )}
    </IonGrid>
  ) : (
    <div>Cargando</div>
  );
}
