import { IonContent, IonPage, IonLoading } from "@ionic/react";

import React, { useState, useEffect } from "react";

import "./User.css";

import {
  getAllUsu,
  getImgUsu,
  getTopUsu,
  getIdsUserNot,
  getTotalSale,
  getLocalUser,
} from "../../api/user/user";
import { Header } from "../../components/Header/Header";
import { UserCards } from "../../components/UserCard/UserCard";
import { getAllResumeOrder } from "../../api/pedido/pedido";
import dayjs from "dayjs";
import { getWebUserOrderModification } from "../../api/orderModification/orderModification";
import { putStore } from "../../api/store/store";
import { Toast } from "../../components/Alert/Alert";

const User: React.FC<any> = (props) => {
  const [showLoading, setShowLoading] = useState(true);
  const [render, setRender] = useState(true);
  const [data, setData] = useState<any>([]);
  const [user, setUser] = useState<any>({});

  const [neighborhood, setNeighborhood] = useState("");
  const [locality, setLocality] = useState("");
  const [department, setDepartment] = useState("");
  const [city, setCity] = useState("");
  const [addres, setAddres] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [storePhone, setStorePhone] = useState("");

  const [dataSearch, setDataSearch] = useState<any>([]);
  const [search, setSearch] = useState<any>("");

  const [toast, setToast] = useState(false);
  const [mensaje, setMensaje] = useState("");

  const [dataNotification, setDataNotification] = useState<any>([]);

  const [idStatusUser, setIdStatusUser] = useState("");

  const [searchIdStatus, setSearchIdStatus] = useState("");

  const [rol, setRol] = useState("");

  const [valuesArray, setValuesArray] = useState<any>([]);

  const confirmUser = async () => {
    const resLocal = await getLocalUser();
    setRol("" + resLocal.idRole);
  };

  confirmUser();

  const preSetSearch = (v: string) => {
    //console.log("v", v)
    if (v === "") {
      setSearch(v);
      return;
    }

    let newData: any[] = [];

    data.forEach((obj: any) => {
      const filter =
        obj.name.toUpperCase() +
        " " +
        obj.lastName.toUpperCase() +
        " " +
        obj.identification.toUpperCase() +
        " " +
        obj.email.toUpperCase();
      const words = v.toUpperCase().split(" ");
      let flag = true;
      words.forEach((word: any, i: number) => {
        if (!flag) return;

        if (!filter.includes(word)) {
          flag = false;
        }

        if (flag && i === words.length - 1) {
          if (!newData) newData = [obj];
          else newData.push(obj);
        }
      });
    });

    setDataSearch(newData);

    setSearch(v);
  };

  const preSetSearchStatus = (v: string) => {
    console.info("vvvv", v, data[0].idStatus);
    setSearchIdStatus(v);
    let newData: any[] = [];
    data.forEach((obj: any) => {
      if (obj.idStatus == v) {
        newData.push(obj);
      }
    });
    setDataSearch(newData);
  };

  const changeLatitude = (objStore: any, latitude: number) => {
    //console.info("changeLatitude", latitude);
    setLatitude(latitude);
    const i = user.stores.findIndex((store: any) => store.id === objStore.id);
    if (i > -1) {
      user.stores[i].latitude = latitude;
    }
  };

  const changeLongitude = (objStore: any, longitude: number) => {
    //console.info("changeLongitude", longitude);

    setLongitude(longitude);
    const i = user.stores.findIndex((store: any) => store.id === objStore.id);
    if (i > -1) user.stores[i].longitude = longitude;
    setRender(!render);
  };

  const fetchData = async () => {
    try {
      setShowLoading(true);

      const resLocal = await getLocalUser();

      //console.info('resLocalresLocalresLocal', resLocal)
      setRol("" + resLocal.idRole);

      const result = await getAllUsu();
      //console.log("---")
      //if(!data.length){
      const resultImg = await getImgUsu(result.data[0].id);
      const resultTop = await getTopUsu(result.data[0].id);
      const resultSales = await getTotalSale(result.data[0].id);
      //console.log("resultSales", resultSales)
      result.data[0].totalSale = resultSales.data;
      result.data[0].imgUser = resultImg.data;
      result.data[0].topProducts = resultTop.data;
      //console.log("result.data", result.data)
      setData(result.data);
      setRender(!render);
      //}
      setShowLoading(false);
    } catch (error) {
      console.log("errrorr", error);
    }
  };

  const fetchGetNotifications = async () => {
    try {
      setShowLoading(true);
      const result = await getIdsUserNot();
      let newUser = result.new;
      let imgs = result.imgs;
      let request = result.request;
      let dataNot: any = [];
      let resNewUser = 0;
      let resImgs = 0;
      let resRequest = 0;
      result.data.forEach((element: any, iAll: number) => {
        const i = data.findIndex((user: any) => user.id === element);
        if (i > -1) {
          dataNot.push(data[i]);
        } else {
          if (iAll <= newUser) {
            resNewUser += 1;
          }
          if (iAll > newUser && iAll < newUser + imgs) {
            resImgs += 1;
          }
          if (iAll > newUser + imgs && iAll < newUser + imgs + request) {
            resRequest += 1;
          }
        }
      });
      setValuesArray({
        new: newUser - resNewUser,
        imgs: imgs - resImgs,
        request: request - resRequest,
      });
      setDataNotification(dataNot);
      setShowLoading(false);
    } catch (error) {
      console.log("errrorr", error);
    }
  };

  const fetchGetImg = async (i: number) => {
    try {
      //console.info('data[i].imgUser',i, data, dataSearch)
      if (dataSearch.length > 0) {
        if (dataSearch[i]?.imgUser) return;
      } else {
        if (data[i]?.imgUser) return;
      }

      setShowLoading(true);
      //console.info('dataSearch.length', dataSearch.length)
      const user = dataSearch.length > 0 ? dataSearch[i] : data[i];
      //console.info('++++', user, data[i])
      const result = await getImgUsu(user.id);

      const resultTop = await getTopUsu(user.id);
      const resultSales = await getTotalSale(user.id);
      //console.log("resultSales", resultSales)
      user.totalSale = resultSales.data;
      user.imgUser = result.data;
      //console.info('data[i].imgUserdata[i].imgUser', result.data)
      user.topProducts = resultTop.data;
      setShowLoading(false);
      //console.log('data[i', data[i])
    } catch (error) {
      console.log("errrorr", error);
    }
  };

  const changeUser = async (
    obj: any,
    i: number,
    listadoUser: boolean = false
  ) => {
    console.log("changeUser", obj);
    setShowLoading(true);
    let newI = i;
    if (listadoUser) {
      newI = data.findIndex((item: any) => item.id === obj.id);
    }
    //console.log("newI", newI);
    await fetchGetImg(newI);

    //console.log("obj", obj)
    const resAllResumeOrder = await getAllResumeOrder("1", obj.id);

    const modification = await getWebUserOrderModification(obj.id);
    obj.orderModification = modification.data;

    let newData: any[] = [];
    resAllResumeOrder.data.forEach((element: any) => {
      let t = JSON.stringify(element);
      let temp = { ...JSON.parse(t) };
      temp.promisedDeliveryDate = dayjs(
        temp.promisedDeliveryDate
      ) /*.add(-5, 'h')*/
        .format("DD-MM-YYYY HH:mm");
      newData.push(temp);
    });
    obj.orderDetail = newData;

    setUser(obj);
    //console.log("obj.idStatus", obj.idStatus)
    setIdStatusUser("" + obj.idStatus);

    setRender(!render);

    setShowLoading(false);
  };

  getLocalUser();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data.length) fetchGetNotifications();
  }, [data]);

  useEffect(() => {
    if (data.length) changeUser(data[0], 0);
  }, [data]);

  const updStore = async (idStore: string) => {
    //console.log("updStore", neighborhood, locality, department, city, addres, latitude, longitude, idStore)
    setShowLoading(true);
    console.info(latitude, longitude, user);
    if (!latitude || !longitude) {
      setShowLoading(false);
      setMensaje("Ingrese la dirección");
      setToast(true);
      return;
    }
    const store = {
      id: idStore,
      locality: locality,
      neighborhood: neighborhood,
      addres: addres,
      city: city,
      department: department,
      idStatus: 1,
      latitude: latitude,
      longitude: longitude,
      phone: storePhone,
    };
    const newStore = await putStore(store);

    const i = user.stores.findIndex((store: any) => store.id === idStore);
    if (i > -1) {
      user.stores[i] = newStore.data.data;
    }

    setMensaje("Dirección actualizada");
    setToast(true);

    setShowLoading(false);
  };

  return (
    <IonPage>
      <IonContent className="">
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
        />
        <Header selected={"1"} modulo={"Usuarios"} props={props} rol={rol} />
        {Toast({
          message: mensaje,
          open: toast,
          onDissmis: () => {
            setToast(false);
          },
          showCloseBtn: true,
          duration: 3000,
          position: "bottom",
        })}
        <UserCards
          valueLength={valuesArray}
          preSetSearchStatus={preSetSearchStatus}
          searchStatus={searchIdStatus}
          rol={rol}
          setMensaje={setMensaje}
          setToast={setToast}
          setShowLoading={setShowLoading}
          data={search !== "" || searchIdStatus !== "" ? dataSearch : data}
          dataNotification={dataNotification}
          onClickUser={changeUser}
          user={user}
          setData={(data: any) => {
            setData(data);
            setRender(!render);
          }}
          idStatusUser={idStatusUser}
          setIdStatusUser={setIdStatusUser}
          search={search}
          preSetSearch={(w: string) => preSetSearch(w)}
          setAddres={setAddres}
          setCity={setCity}
          setDepartment={setDepartment}
          setLocality={setLocality}
          setNeighborhood={setNeighborhood}
          setLatitude={changeLatitude}
          setLongitude={changeLongitude}
          setStorePhone={setStorePhone}
          storePhone={storePhone}
          updStore={updStore}
          refreshData={fetchData}
        />
      </IonContent>
    </IonPage>
  );
};

export default User;
