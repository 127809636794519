import { 
  IonContent,
  IonPage,
  IonLoading,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/react';

import React, { useState, useEffect } from 'react';

import './Order.css'

import { Header } from '../../components/Header/Header';

import { getAllOrderWeb, getDetailAll, getIdsOrderNot, getSupport } from '../../api/pedido/pedido';

import { OrderList } from './OrderList';
import { OrderDetail } from './OrderDetail';
import { OrderFinish } from './OrderFinish';
import { getType } from '../../api/type/type';
import { filterStatusOrder, filterStatusOrderOnly } from '../../const/functions';
import dayjs from 'dayjs';
import { Toast } from '../../components/Alert/Alert';
import { getLocalUser } from '../../api/user/user';

const Order: React.FC<any> = (props) => {
  
  const [showLoading, setShowLoading] = useState(false);
  const [render, setRender] = useState(true);
  
  const [toast, setToast] = useState(false);
  const [mensaje, setMensaje] = useState('');

  const [modal, setModal] = useState(false);


  const [allOrder, setAllOrder] = useState<any>([]);
  const [order, setOrder] = useState<any>({});

  
  const [dataNotification, setDataNotification] = useState<any>([]);
  
  const [status, setStatus] = useState<any>([]);
  const [filterStatus, setfilterStatus] = useState<any>([]);

  
  const [rol, setRol] = useState<any>('');
    
  const fetchData = async () => {
    try {
    setShowLoading(true);
    
    const res = await getAllOrderWeb();
    setAllOrder(res.data)
    //setRender(!render)
  
    const res2 = await getType('5');
    //console.log("res type", res2.data, allOrder)
    const newRes = filterStatusOrder(res2.data, false) 
    //console.log("stauts", newRes)

    const newRes2 = filterStatusOrder(res2.data, true) 

    setStatus(newRes)
    setfilterStatus(newRes2)
    
    setShowLoading(false);

    } catch (error) {
      console.log("errrorr", error)
    }

  }

  
  const fetchGetNotifications = async () => {
    try {
    setShowLoading(true);
    const result = await getIdsOrderNot();

    //console.log("data.lenghtdata.lenght",allOrder.length,result.data)
    let dataNot: any = []
    result.data.forEach( (element: any) => {
      const i = allOrder.findIndex( (order: any) => order.id === element )
      if(i>-1)
        dataNot.push(allOrder[i])
    });

    setDataNotification(dataNot)
    
      
    setShowLoading(false);

    } catch (error) {
      console.log("errrorr", error)
    }

  }

  useEffect(() => {
    fetchData()
    getLocalUser().then( (res2: any) => {
      //console.info('res2', res2)
      setRol(""+res2.idRole)
    })
  }, [])


  useEffect(() => {
    //console.log("allOrder.....")
    if(allOrder.length)
      fetchGetNotifications()
  }, [allOrder])


  const getDetailOrder = async () => {
    let order = {...allOrder[0]}
    const orderSupport = await getSupport(order.id);
    const res = await getDetailAll(order.id);
    //console.log("----------------------------", orderSupport.support)
    order.support = orderSupport.support
    order.detail = res.data.detail
    order.modification = res.data.modification
    allOrder[0] = order
    setAllOrder(allOrder)
    //console.log("promisedDeliveryDatepromisedDeliveryDate", order.promisedDeliveryDate)
    order.date = dayjs(order.date).add(5, 'h')
    //console.log("-order-", order)
    setOrder(order)
  }

  useEffect(() => {
    if(allOrder.length){
      //console.log('ALLLLLLOOORRDER',allOrder[0])
    //console.log("allOrderaaa", allOrder)
    getDetailOrder()
    }
  }, [allOrder])

  
  const fetchStatuss = async () => {
    const res2 = await getType('5');
    //console.log("res type", res2.data, allOrder)
    //console.log("order status", order.idStatus)
    
    const newRes = filterStatusOrderOnly(res2.data, order.idStatus) 
    //console.log("stauts...", newRes)

    setStatus(newRes)
  }

  useEffect(() => {
    //console.log("change")
    setRender(!render)
    fetchStatuss()
  }, [order])

  
/*
  const logOut = () => {
    setShowLoading(true)
    console.log("out")
    removeSesionData().then( () => {
      props.history.push('/login')
      setShowLoading(false)
    })
    
  }
*/
  return (
    <IonPage>
      <IonContent className="">

      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={'Please wait...'}
      />

            
      {Toast( {
          message: mensaje,
          open: toast,
          onDissmis: () => { setToast(false)},
          showCloseBtn: true,
          duration: 3000,
          position:'bottom',
          }
        )}
        
      <Header
        selected={'5'}
        modulo={'Ordenes'}
        props={props}
        rol={rol}
      />

      <div>
        <IonGrid>
          <IonRow>
            <IonCol size={'5'}>
              <OrderList
                rol={rol}
                setOrder={(order: any) => {setOrder({...order}); setRender(!render)}}
                allOrder={allOrder}
                setAllOrder={setAllOrder}
                setFillter={() => {}}
                status={filterStatus}
                setRender={() => setRender(!render)}
                loading={setShowLoading}
                dataNotification={dataNotification}
                fetchGetNotifications={fetchGetNotifications}
                fetchData={fetchData}
              />
            </IonCol>
            <IonCol size={'7'}>
              <IonGrid>
                <IonRow>
                  <IonCol size={'6'}>
                    <OrderDetail
                      rol={rol}
                      order={order}
                      setAllOrder={setAllOrder}
                      allOrder={allOrder}
                      status={status}
                      setRenderFather={() => setRender(!render)}
                      loading={setShowLoading}
                      setModal={setModal}
                      modal={modal}
                      setOrder={(order: any) => {setOrder({...order}); setRender(!render)}}
                    />
                  </IonCol>
                  <IonCol size={'6'}>
                    <OrderFinish
                      rol={rol}
                      order={order}
                      allOrder={allOrder}
                      loading={setShowLoading}
                      setAllOrder={setAllOrder}
                      setMensaje={setMensaje}
                      setToast={setToast}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>

          </IonRow>
        </IonGrid>
      </div>
      
      </IonContent>
    </IonPage>
  );
    
  }


export default Order;
