import { getAxio } from '../api';

const path = "store/"

export function post(data: any){
    return getAxio().post(path, data).then((res:any) => res ) 
}


export function getAllStore(idZona: string){
    return getAxio().get(path + 'zone/' + idZona).then((res:any) => {
        return res.data
     } ) 
}


export function putStore(data: any){
    return getAxio().put(path, data).then((res:any) => res ) 
}
