import { IonGrid, IonRow, IonCol } from "@ionic/react";
import React from "react";
import './Notification.css'
import LazyImage from "../../components/lazy-image/lazy-image";

interface PropNotifications{
    data: any[]
    setNotification: Function
    setShowLoading: Function
}

export function Notifications ({
    data,
    setNotification
}:PropNotifications ){
    return (
        <IonGrid>
            <IonRow>
            { data.map( (notification: any, i: number) => {
                return (
                    <IonCol size={'3'} key={i} onClick={() => setNotification({...notification})}>
                        <IonGrid>
                            <IonRow>
                                <IonCol className='ion-text-justify notificationTxt'>
                                    {notification.txt}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <LazyImage
                                    src={notification?.img}
                                    alt={'Image Notification'}
                                />
                            </IonRow>
                            <IonRow>
                                <IonCol className='ion-text-justify notificationTxt'>
                                    Estado: {notification?.status ? 'Activa' : 'Inactiva'}
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCol>
                    
                )
            })}
            </IonRow>
        </IonGrid>
    )
}