import { IonGrid, IonRow, IonCol } from "@ionic/react";
import React from "react";
import { Input } from "../../components/Inputs/Input";

interface PropFilter {
  setSearch: Function;
  search: string;
}

export function PromotionFilter({ setSearch, search }: PropFilter) {
  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <Input
            txt="Buscar"
            onInput={(v: string) => setSearch(v)}
            value={search}
            positionTxt={"floating"}
            type={"text"}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
}
