import React from 'react'
import 'lazysizes'
import { LOGO } from '../../const/imgs'

interface LazyImageProps {
  alt: string
  src: string
  previewSrc?: string
  className?: string
  width?: string | number
  height?: string | number
}

export default function LazyImage({
  alt,
  src,
  previewSrc,
  className = '',
  ...props
}: LazyImageProps) {
  return (
    <img
      draggable={false}
      className={`lazyload blur-up ${className}`}
      alt={alt}
      src={LOGO}
      data-srcset={src}
      {...props}
    />
  )
}
