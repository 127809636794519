import {
  IonContent,
  IonPage,
  IonLoading,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";

import React, { useState, useEffect } from "react";

import "./Calendar.css";

import { Header } from "../../components/Header/Header";

import { CalendarForm } from "./CalendarForm";
import { getLocalUser } from "../../api/user/user";
import { Calendars } from "./Calendars";
import dayjs from "dayjs";
import {
  getDaysOfYear,
  postCalendar,
  putCalendar,
  deleteCalendar,
} from "../../api/calendar/calendar";
import moment from "moment";

const Calendar: React.FC<any> = (props) => {
  const [showLoading, setShowLoading] = useState(true);
  const [calendar, setCalendar] = useState<any>(dayjs());
  const [objCalendar, setObjCalendar] = useState<any>(dayjs());
  const [hollidays, setHollidays] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [rol, setRol] = useState("");
  const [year, setYear] = useState<any>(dayjs());

  const fetchData = async () => {
    try {
      setShowLoading(true);
      const result = await getDaysOfYear(year.format("YYYY"));
      const hollidays: string[] = [];
      const newData: any[] = [];
      result.data.forEach((element: any) => {
        hollidays.push(element.date);
        newData.push({ ...element, dateJs: dayjs(element.date) });
      });
      setData(newData);
      setHollidays(hollidays);
      setCalendar(dayjs());
      setShowLoading(false);
    } catch (error) {
      console.log("errrorr", error);
    }
  };

  const saveCalendar = async (calendar: any) => {
    try {
      setShowLoading(true);
      if (calendar.id) {
        if (calendar.calendarDets.length > 0) {
          await putCalendar(calendar);
        } else {
          await deleteCalendar(calendar.id);
        }
      } else {
        if (calendar.calendarDets.length > 0) {
          await postCalendar(calendar);
        }else {
          setShowLoading(false);
        }
        
      }
      fetchData();
    } catch (error) {
      console.log("errrorr", error);
    }
  };

  useEffect(() => {
    fetchData();
    getLocalUser().then((res: any) => {
      setRol("" + res.idRole);
    });
  }, []);

  useEffect(() => {
    selectObjCalendar(calendar.format("YYYY-MM-DD"));
  }, [calendar]);

  useEffect(() => {
    if (year) {
      fetchData();
    }
  }, [year]);

  const selectObjCalendar = (date: any) => {
    const i = data.findIndex((obj: any) => obj.date === date);
    if (i > -1) {
      let t = JSON.stringify(data[i]);
      let temp = JSON.parse(t);
      temp.dateJs=dayjs(date)
      setObjCalendar(temp);
    } else {
      setObjCalendar({
        date: date,
        dateJs: dayjs(date),
        calendarDets: [],
      });
    }
  };
  const changeYear = (add: boolean) => {
    if (add) {
      setYear(year.add(1, "y"));
    } else {
      setYear(year.add(-1, "y"));
    }
  };

  return (
    <IonPage>
      <IonContent className="">
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
        />
        <Header selected={"7"} modulo={"Publicidad"} props={props} rol={rol} />
        <div>
          <IonGrid>
            <IonRow>
              <IonCol size={"8"}>
                <IonGrid>
                  <IonRow>
                    <IonCol
                      className={"notifications"}
                      style={{ overflowY: "scroll" }}
                    >
                      <Calendars
                        year={Number(year.format("YYYY"))}
                        changeYear={changeYear}
                        hollidays={hollidays}
                        setDayOfCalendar={setCalendar}
                        selectedDay={moment(objCalendar.date)}
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
              <IonCol size={"4"}>
                <IonRow>
                  <IonCol
                    className={"products2"}
                    style={{ overflowY: "scroll" }}
                  >
                    <CalendarForm
                      calendar={{...objCalendar}}
                      saveCalendar={saveCalendar}
                    />
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Calendar;
