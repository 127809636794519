import React, { useState, useEffect } from "react";

import "./Map.css";

import { GoogleMap, Marker, Polygon } from "@react-google-maps/api";

import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

import GooglePlacesAutocomplete from "react-google-places-autocomplete";
// If you want to use the provided css
import "react-google-places-autocomplete/dist/index.min.css";

interface PropMap {
  loading: Function;
  setNeighborhood: Function;
  setLocality: Function;
  setDepartment: Function;
  setCity: Function;
  setAddres: Function;
  setLatitude: Function;
  setLongitude: Function;
  center_?: any;
  dataMarker?: any;
  polygone_?: any;
  pathsPoligone?: any[];
  className?: any;
  initLoc?: any;
  storeId: any
}

export function Map({
  loading,
  setNeighborhood,
  setLocality,
  setDepartment,
  setCity,
  setAddres,
  setLatitude,
  setLongitude,
  center_,
  dataMarker,
  polygone_,
  className,
  initLoc,
  pathsPoligone,
  storeId
}: PropMap) {
  const [location, setLocation] = useState<any>({});
  const [initialAddres, setInitialAddres] = useState("");

  const setData = (addresComponent: any[]) => {
    let iStreet2: String = "",
      iRoute2: String = "",
      iNeighborhood2: String = "",
      isubLocality2: String = "",
      iCity2: String = "",
      iDepartment2: String = "";

    addresComponent.forEach((addres: any, index: number) => {
      //console.log("addres", addres)
      const iStreet = addres.types.findIndex(
        (type: string) => type === "street_number"
      );
      if (iStreet > -1) iStreet2 = addres.long_name;

      const iRoute = addres.types.findIndex((type: string) => type === "route");
      if (iRoute > -1) iRoute2 = addres.long_name;

      const iNeighborhood = addres.types.findIndex(
        (type: string) => type === "neighborhood"
      );
      if (iNeighborhood > -1) iNeighborhood2 = addres.long_name;

      const isubLocality = addres.types.findIndex(
        (type: string) => type === "sublocality"
      );
      if (isubLocality > -1) isubLocality2 = addres.long_name;

      const iCity = addres.types.findIndex(
        (type: string) => type === "administrative_area_level_1"
      );
      if (iCity > -1) iCity2 = addres.long_name;

      const iDepartment = addres.types.findIndex(
        (type: string) => type === "administrative_area_level_2"
      );
      if (iDepartment > -1) iDepartment2 = addres.long_name;
    });
    setAddres(iRoute2 + " " + iStreet2);
    setCity(iDepartment2);
    setDepartment(iCity2);
    setLocality(isubLocality2);
    setNeighborhood(iNeighborhood2);
    //console.log("daatoos addres", iStreet2, iRoute2, 'neighborhood',iNeighborhood2, 'sublocality', isubLocality2, 'administrative_area_level_1', iCity2, 'administrative_area_level_2', iDepartment2)
  };

  const fetchApiFromLatLng = (lat: any, lng: any) => {
    console.info('lat', lat, lng )
    fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
        lat +
        "%20" +
        lng +
        "&key=AIzaSyDzp1SQj-bar6_QU2tCyEvBjHegEROQA0k"
    )
      .then((res: any) => res.json())
      .then((data: any) => {
        console.info("daaaaaaaaaaaaa", data);
        if (data.results[0]) {
          console.log(
            "data.results[0].address_components",
            data.results[0].address_components
          );
          console.log("addre111s", data.results[0].formatted_address);
          setInitialAddres(data.results[0].formatted_address);
          setData(data.results[0].address_components);
          setLatitude({}, lat);
          setLongitude({}, lng);
        }
      });
  };

  const showCurrentLocation = () => {
    if (navigator.geolocation) {
      if (initLoc) {
        setLocation(initLoc);
      } else {
        navigator.geolocation.getCurrentPosition((position) => {
          //console.log("position.coords", position)
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        });
      }
    } else {
      //console.log("error")
    }
  };

  if (!location?.lat) {
    showCurrentLocation();
  }

  const onPositionChanged = (a: any) => {
    //console.log("bbbb",a)
  };

  const dragChange = (e: any) => {
    //console.log("dragChange", e.latLng.lat(), e.latLng.lng())
    fetchApiFromLatLng(e.latLng.lat(), e.latLng.lng());
  };

  const addresToLtLng = async (addres: string) => {
    loading(true);
    //console.log(11111111111)
    geocodeByAddress(addres)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        //console.log('Successfully got latitude and longitude', { lat, lng })
        fetchApiFromLatLng(lat, lng);
        setLocation({ lat, lng });
        loading(false);
      });
  };

  useEffect(() => {
    console.info("useeff", storeId, location, initLoc);
    if (initLoc.lat) {
      setLocation(initLoc)
      //setInitialAddres('alla')
      fetchApiFromLatLng(initLoc.lat, initLoc.lng);
    }
  }, [storeId]);

  return (
    <div className="App">
      {!className && (
        <div>
          <GooglePlacesAutocomplete
            placeholder="Dirección"
            initialValue={initialAddres}
            onSelect={({ description }: any) => addresToLtLng(description)}
            autocompletionRequest={{
              componentRestrictions: {
                country: "CO",
              },
            }}
          />
          <br />
        </div>
      )}
      <GoogleMap
        clickableIcons={false}
        mapContainerClassName={!className ? "App-map" : "App-map2"}
        center={center_?.lat ? center_ : location}
        zoom={polygone_ ? 12 : 16}
      >
        {polygone_ && (
          <Polygon
            paths={pathsPoligone}
            options={{
              fillColor: "lightblue",
              strokeColor: "red",
              strokeWeight: 1,
            }}
          />
        )}
        {!dataMarker && (
          <Marker
            position={location}
            draggable={true}
            onDragEnd={(e: any) => dragChange(e)}
            onPositionChanged={() => onPositionChanged}
            title={"CORABASTO"}
          />
        )}
        {dataMarker &&
          dataMarker.map((element: any) => (
            <Marker
              position={element.location}
              draggable={false}
              title={element.name}
            />
          ))}
      </GoogleMap>
    </div>
  );
}
