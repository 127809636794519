import { getAxio } from '../api';

const path = "promotion/"

export function getPromotion(){
    return getAxio().get(path + 'all').then((res:any) => {
        return res.data
     } ) 
}

export function getPromotionById(id: string){
    return getAxio().get(path + 'id/' + id).then((res:any) => {
        return res.data
     } ) 
}

export function getProducts(type:string){
    return getAxio().get(path + 'categories/' + type).then((res:any) => {
        return res.data
     } ) 
}


export function putPromotion(value:any ){
    return getAxio().put(path, value).then((res:any) => {
        return res.data
     } ) 
}


export function postPromotionDetail(value:any ){
    return getAxio().post(path + 'promotion-detail', value).then((res:any) => {
        return res.data
     } ) 
}



export function sendPushPromo(value:any ){
    return getAxio().post(path + 'push-promo', value).then((res:any) => {
        return res.data
     } ) 
}


export function deletePromotionDetail(idDetail: string ){
    return getAxio().delete(path + 'detail/' + idDetail).then((res:any) => {
        return res.data
     } ) 
}

