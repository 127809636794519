import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonButton,
  IonAvatar,
  IonImg,
  IonTextarea,
  IonToggle,
} from "@ionic/react";
import React, { useState } from "react";
import "./PromotionForm.css";
import { Input } from "../../components/Inputs/Input";
import dayjs from "dayjs";
import { postPromotionDetail } from "../../api/promotion/promotion";
import { LUPA, LOGO, NEGAR, TRASH } from "../../const/imgs";
import NumberFormat from "react-number-format";

interface PropProductForm {
  promotion: any;
  savePromotion: Function;
  search: string;
  setSearch: Function;
  searchProducts: any;
  onClickProduct: Function;
  loadingSearch: Boolean;
  setShowModalPresentation: Function;
  deleteDetail: Function;
  setObjProduct: Function;
  setIObjProduct: Function;
  rol: string;
}

export function PromotionForm({
  promotion = {},
  savePromotion,
  deleteDetail,
  search,
  setSearch,
  searchProducts,
  onClickProduct,
  loadingSearch,
  setShowModalPresentation,
  setObjProduct,
  setIObjProduct,
  rol,
}: PropProductForm) {
  const [render, setRender] = useState(false);

  const calcDescuento = () => {
    let discount = 0;
    (promotion.promotionDetails || []).forEach((detail: any) => {
      discount += Number(detail.discount * detail.quantity);
    });
    promotion.discount = discount;
    return discount;
  };

  const calcTotal = () => {
    let total = 0;
    (promotion.promotionDetails || []).forEach((detail: any) => {
      const price =
        detail.product.iPresentation > -1
          ? `${
              detail.product.presentations[detail.product.iPresentation].price *
              detail.quantity
            }`
          : `${detail.pricePresentation * detail.quantity}`;
          console.info('price', detail)
      total += parseFloat(price);
    });
    const calcDes = calcDescuento();
    promotion.discount = calcDes;
    promotion.price = total - promotion.discount;
    return total - calcDescuento();
  };

  const getDiscount = (detail: any, type: number, discount: string) => {
    if (type === 1) {
      detail.discount = Number(discount);
    }
    if (type === 2) {
      const price =
        detail.product.iPresentation > -1
          ? detail.product.presentations[detail.product.iPresentation].price
          : detail.pricePresentation;
      const x = price * (Number(discount) / 100);
      detail.discount = x;
    }
    calcDescuento();
    calcTotal();
    setRender(!render);
  };

  const getPresentation = (detail: any) => {
    let i =
      detail.product.iPresentation > -1 ? detail.product.iPresentation : -1;
    if (i === -1)
      i = detail.product.presentations.findIndex(
        (presentation: any) => presentation.id === detail.idPresentation
      );
    return detail.product.presentations[i];
  };

  const getPrecioPresentation = (detail: any, type: number = 0) => {
    let i =
      detail.product.iPresentation || detail.product.iPresentation === 0
        ? detail.product.iPresentation
        : -1;
    console.info("iii", i);
    if (i === -1) {
      i = detail.product.presentations.findIndex(
        (presentation: any) => presentation.id === detail.idPresentation
      );
    }
    console.info("iii2", i, detail.product.presentations);
    return type === 0
      ? detail.product.presentations[i].price
      : detail.product.presentations[i].price - detail.discount;
  };

  const getTotal = (detail: any, type: number = 0) => {
    let i =
      detail.product.iPresentation || detail.product.iPresentation === 0
        ? detail.product.iPresentation
        : -1;
    if (i === -1)
      i = detail.product.presentations.findIndex(
        (presentation: any) => presentation.id === detail.idPresentation
      );
      console.info('iii,,,', i)
    return type === 0
      ? `${detail.product.presentations[i].price * detail.quantity}`
      : `${
          (detail.product.presentations[i].price - detail.discount) *
          detail.quantity
        }`;
  };

  const getPercen = (detail: any) => {
    if (detail.discount === 0 || !detail.discount) return "" + 0;
    const price =
      detail.product.iPresentation > -1
        ? detail.product.presentations[detail.product.iPresentation].price
        : detail.pricePresentation;
    return "" + ((detail.discount / price) * 100).toFixed(2);
  };
  const changePrecio = (detail: any, value: string) => {
    detail.pricePresentation = value;
    setRender(!render);
  };
  const changeQuantity = (detail: any, value: string) => {
    detail.quantity = value;
    setRender(!render);
  };

  const clickProd = async (prod: any) => {
    //console.log("piiii", prod)
    const r = await postPromotionDetail({ ...prod, idPromotion: promotion.id });
    //console.log("pppppp", r.data)
    promotion.promotionDetails.push(r.data);
    onClickProduct();
  };

  return (
    <IonGrid>
      <IonRow>
        <IonCol size={"6"} className={"promotionFormName"}>
          <Input
            txt="Nombre"
            onInput={(value: string) => {
              promotion.name = value;
            }}
            value={promotion.name}
            positionTxt={"floating"}
            type={"text"}
          />
        </IonCol>
        <IonCol size={"6"}>
          <IonImg className="promotionFormImg" src={promotion.img || LOGO} />
        </IonCol>
      </IonRow>
      <IonRow>
      <IonCol size={"12"} className={"promotionFormName"}>
          <Input
          disabled
            txt="Puntos SanApp"
            onInput={(value: string) => {
              promotion.points = value;
            }}
            value={promotion.points}
            positionTxt={"floating"}
            type={"text"}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size={"12"}>
          <IonTextarea
            placeholder={"Detalle"}
            value={promotion.description}
            onIonChange={(e: any) => (promotion.description = e.detail.value)}
            className="orderRow promotionFormArea"
            rows={3}
          />
        </IonCol>
        <IonCol size={"12"}>
          <IonTextarea
            placeholder={"Política"}
            value={promotion.politica}
            onIonChange={(e: any) => (promotion.politica = e.detail.value)}
            className="orderRow promotionFormArea"
            rows={3}
          />
        </IonCol>
        <IonCol size={'6'} >
            <IonItem lines="none" className='productFormBack productFormSuggested'>
                Mostrar en el Banner
                <IonToggle  checked={promotion.showBanner} onIonChange={e => { promotion.showBanner = (!promotion.showBanner)}} />
            </IonItem>
        </IonCol>
      </IonRow>
      {rol.includes("3.1") && (
        <IonRow>
          <IonCol size={"12"}>
            <Input
              txt="Producto"
              onInput={(value: string) => setSearch(value)}
              value={search}
              positionTxt={"floating"}
              type={"text"}
              icon={LUPA}
              icon2={NEGAR}
              onClickIcon2={() => onClickProduct()}
            />
            <div className="search" style={{ overflowY: "scroll" }}>
              {loadingSearch && (
                <div className="itemTxt2 ion-text-center">Loading ...</div>
              )}
              {!loadingSearch &&
                searchProducts.map((element: any, index: number) => {
                  return (
                    <div key={index}>
                      {!loadingSearch && (
                        <IonItem
                          className="itemTxt"
                          onClick={() => clickProd(element)}
                        >
                          <IonAvatar slot="start">
                            <img src={element.img} alt={"img"} />
                          </IonAvatar>
                          <IonLabel>
                            {element.content + " " + element.name}
                          </IonLabel>
                        </IonItem>
                      )}
                    </div>
                  );
                })}
            </div>
          </IonCol>
        </IonRow>
      )}
      <IonRow>
        <IonGrid>
          {(promotion.promotionDetails || []).map(
            (detail: any, index: number) => {
              return (
                <IonRow key={index}>
                  <IonGrid>
                    <IonRow>
                      {rol.includes("3.2") && (
                        <IonCol
                          size={"1"}
                          className=""
                          onClick={() => deleteDetail(detail)}
                        >
                          <IonImg
                            src={TRASH}
                            className={"promotionFormTrash"}
                          />
                        </IonCol>
                      )}
                      <IonCol size={"11"}>
                        <IonGrid>
                          <IonRow>
                            <IonCol size={"2"} className={""}>
                              <Input
                                txt="Nombre"
                                onInput={(value: string) => {}}
                                value={detail.product.name}
                                positionTxt={"floating"}
                                type={"text"}
                                disabled
                                className="promotionBackColor promotionFormInput"
                              />
                            </IonCol>
                            <IonCol
                              size={"2"}
                              onClick={() => {
                                setShowModalPresentation(true);
                                setIObjProduct(index);
                                setObjProduct(detail.product);
                              }}
                            >
                              <Input
                                txt="Presentación"
                                onInput={(value: string) => {}}
                                value={getPresentation(detail).detail}
                                positionTxt={"floating"}
                                type={"text"}
                                disabled
                                className="promotionBackColor promotionFormInput"
                              />
                            </IonCol>
                            <IonCol size={"6"}>
                              <IonGrid className={"productFormPaddingCero"}>
                                <IonRow>
                                  <IonCol
                                    size={"3"}
                                    className="productFormPaddingCero2"
                                  >
                                    <Input
                                      txt="Precio"
                                      onInput={(value: string) => {
                                        changePrecio(detail, value);
                                      }}
                                      value={getPrecioPresentation(detail)}
                                      positionTxt={"floating"}
                                      type={"text"}
                                      className="promotionBackColor promotionFormInput"
                                      disabled
                                    />
                                  </IonCol>
                                  <IonCol
                                    size={"3"}
                                    className="productFormPaddingCero2"
                                  >
                                    <Input
                                      txt="Descuento"
                                      onInput={(value: string) => {
                                        getDiscount(detail, 1, value);
                                      }}
                                      value={detail.discount}
                                      positionTxt={"floating"}
                                      type={"text"}
                                      className="promotionBackColor promotionFormInput"
                                    />
                                  </IonCol>
                                  <IonCol
                                    size={"3"}
                                    className="productFormPaddingCero2"
                                  >
                                    <Input
                                      txt="%"
                                      onInput={(value: string) => {
                                        getDiscount(detail, 2, value);
                                      }}
                                      value={getPercen(detail)}
                                      positionTxt={"floating"}
                                      type={"text"}
                                      className="promotionBackColor promotionFormInput"
                                    />
                                  </IonCol>
                                  <IonCol
                                    size={"3"}
                                    className="productFormPaddingCero2"
                                  >
                                    <Input
                                      txt="Cantidad"
                                      onInput={(value: string) => {
                                        changeQuantity(detail, value);
                                      }}
                                      value={detail.quantity}
                                      positionTxt={"floating"}
                                      type={"number"}
                                      className="promotionBackColor promotionFormInput"
                                    />
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonCol>

                            <IonCol size={"2"}>
                              <Input
                                txt="Total"
                                onInput={(value: string) => {
                                  promotion.content = value;
                                }}
                                value={getTotal(detail, 1)}
                                positionTxt={"floating"}
                                type={"text"}
                                className="promotionBackColor promotionFormInput"
                                disabled
                              />
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonRow>
              );
            }
          )}
        </IonGrid>
      </IonRow>
      <IonRow className={"promotionFormDescuento"}>
        <IonCol size={"4"}>Descuento</IonCol>
        <IonCol size={"8"}>
          <NumberFormat
            prefix="$ "
            thousandSeparator="."
            decimalSeparator=","
            value={calcDescuento()}
            displayType={"text"}
          />
        </IonCol>
      </IonRow>
      <IonRow className={"promotionFormTotal"}>
        <IonCol size={"4"}>Total:</IonCol>
        <IonCol size={"8"}>
          <NumberFormat
            prefix="$ "
            thousandSeparator="."
            decimalSeparator=","
            value={calcTotal()}
            displayType={"text"}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size={"12"}>
          <div className={"productFormPoli"}>Políticas</div>
        </IonCol>
        <IonCol size={"5"}>
          <div className={"productFormBackOther"}>cantidad</div>
        </IonCol>
        <IonCol size={"7"}>
          <Input
            txt=" "
            onInput={(value: string) => {
              promotion.stock = value;
            }}
            value={promotion.stock}
            positionTxt={"floating"}
            type={"number"}
          />
        </IonCol>
        <IonCol size={"4"}>
          <div className={"productFormBackDate"}>Vigencia</div>
        </IonCol>
        <IonCol size={"4"}>
          <Input
            txt="Desde"
            onInput={(value: string) => {
              promotion.dateIni = dayjs(value);
            }}
            value={"" + dayjs(promotion.dateIni).format("YYYY-MM-DD")}
            positionTxt={"floating"}
            type={"date"}
          />
        </IonCol>
        <IonCol size={"4"}>
          <Input
            txt="Hasta"
            onInput={(value: string) => {
              promotion.dateFin = dayjs(value);
            }}
            value={"" + dayjs(promotion.dateFin).format("YYYY-MM-DD")}
            positionTxt={"floating"}
            type={"date"}
          />
        </IonCol>
        <IonCol size={"5"}>
          <div className={"productFormBackOther"}>Unidades por pedido</div>
        </IonCol>
        <IonCol size={"7"}>
          <Input
            txt=" "
            onInput={(value: string) => {
              promotion.orderQuantity = value;
            }}
            value={promotion.orderQuantity}
            positionTxt={"floating"}
            type={"number"}
          />
        </IonCol>
      </IonRow>
      {rol.includes("3.3") && (
        <IonRow>
          <IonButton
            className="productFormSave"
            onClick={() => savePromotion()}
          >
            Guardar
          </IonButton>
        </IonRow>
      )}
    </IonGrid>
  );
}
