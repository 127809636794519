import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonPage } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/core/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/core/css/normalize.css';
import '@ionic/core/css/structure.css';
import '@ionic/core/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/core/css/padding.css';
import '@ionic/core/css/float-elements.css';
import '@ionic/core/css/text-alignment.css';
import '@ionic/core/css/text-transformation.css';
import '@ionic/core/css/flex-utils.css';
import '@ionic/core/css/display.css';


/* Theme variables */
import './theme/variables.css';



import Login from './pages/Login/Login';
import User from './pages/User/User';
import Product from './pages/Product/Product';
import Promotion from './pages/Promotion/Promotion';
import Order from './pages/Order/Order';
import Zone from './pages/Zone/Zone';
import Cardinalidad from './pages/Cardinalidad/Cardinalidad';
import Notification from './pages/Notification/Notification';
import Calendar from './pages/Calendar/Calendar';

const App: React.FunctionComponent = () => (
  <IonApp>
    <IonReactRouter>
      <IonPage id="main">
        <IonRouterOutlet>
          <Route path="/login" component={Login} exact={true} />
          <Route path="/user" component={User} exact={true} />
          <Route path="/product" component={Product} exact={true} />
          <Route path="/promotion" component={Promotion} exact={true} />
          <Route path="/order" component={Order} exact={true} />
          <Route path="/zone" component={Zone} exact={true} />
          <Route path="/logistica" component={Cardinalidad} exact={true} />
          <Route path="/notification" component={Notification} exact={true} />
          <Route path="/calendar" component={Calendar} exact={true} />
          <Route path="/" render={() => <Redirect to="/login" />} exact={true} />
        </IonRouterOutlet>
      </IonPage>
    </IonReactRouter>
  </IonApp>
);

export default App;